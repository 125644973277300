import React from "react";
import styled from "styled-components";
import { Grid, IconButton, Icon, Tabs, Tab } from "@material-ui/core";
import DataGridWrapper from "components/Molecules/DataGrid/DataGrid";
import { useQuery } from "@tanstack/react-query";
import apiServices from "services/api/campaign.js";
import _ from "lodash";
import copy from "copy-to-clipboard";

const { getAllFieldOptionsFromDirectus } = apiServices;

const StyledContainer = styled.div`
  width: 100%;
  position: relative;
  // border: 4px solid red;
  @media (max-width: 1024px) {
    padding: 0;
  }
`;

const UtmDictionary = () => {

  const [tabValue, setTabValue] = React.useState(0);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const {data:allFieldOptions={}, status: campaignsListStatus} = useQuery(["all-field-options"], getAllFieldOptionsFromDirectus);
  const {utm_medium=[], utm_source=[], channel_codes=[], campaign_advertisers=[], utm_campaign_types=[], utm_campaign_strategy_type=[], utm_campaign_categories=[], utm_campaign_display_type=[], utm_campaign_hub=[]} = allFieldOptions;

  const renderTableForTabIndex = (index) => {
    switch(index){
      case 0: return <DataGridWrapper columns={commonHeaders} rows={campaign_advertisers} /> // advertisers
      case 1: return <DataGridWrapper columns={commonHeaders} rows={utm_campaign_categories} /> // categories
      case 2: return <DataGridWrapper columns={campaignDisplayType} rows={utm_campaign_display_type} /> // campaign display type
      case 3: return <DataGridWrapper columns={commonHeaders} rows={utm_campaign_hub} /> // hubs
      case 4: return <DataGridWrapper columns={commonHeaders} rows={utm_campaign_strategy_type} /> // strategy types
      case 5: return <DataGridWrapper columns={channelCodesColumns} rows={channel_codes} /> // channel codes
      case 6: return <DataGridWrapper columns={utmMediumAndSource} rows={utm_medium} /> // utm_medium
      case 7: return <DataGridWrapper columns={utmMediumAndSource} rows={utm_source} /> // utm_source
      default: return <DataGridWrapper columns={utmMediumAndSource} rows={utm_medium} />
    }
  }

  return (
    <StyledContainer>
      <Grid container spacing={2} style={{paddingBlock: 8, paddingBottom: 0, marginBottom: 16}}>
        <Grid item xs={12} container justifyContent="flex-start" alignItems="center">
            <Tabs
              value={tabValue}
              indicatorColor="primary"
              textColor="primary"
              onChange={handleTabChange}
              aria-label="disabled tabs example"
              scrollButtons="auto"
              variant="scrollable"
            >
              <Tab label="Advertisers" />
              <Tab label="Categories" />
              <Tab label="Campaign Display Type" />
              <Tab label="Hubs" />
              <Tab label="Strategy Types" />
              <Tab label="Channel Codes" />
              <Tab label="UTM Mediums" />
              <Tab label="UTM Sources" />
            </Tabs>
          {/* </Paper> */}
        </Grid>
      </Grid>
      {renderTableForTabIndex(tabValue)}
      {/* {
        tabValue===0
          ? <DataGridWrapper columns={utmMediumAndSource} rows={utm_medium} />
          : tabValue===1
            ? <DataGridWrapper columns={utmMediumAndSource} rows={utm_source}/>
            : null
      } */}
    </StyledContainer>
  );
};

export default UtmDictionary;

const textListFormatter = new Intl.ListFormat('en', { style: 'long', type: 'conjunction' });

const campaignDisplayType = [
  {
    field: 'name', headerName: 'Name', width: 400, flex: 0.5,
    renderCell: (params) => (
      <div style={{ width: "100%", display: "flex", gap: 8, alignItems: "center" }}>
        <p style={{ width: "100%", wordBreak: "break-all", whiteSpace: "normal", marginBlock: "inherit" }}>
          {params.value}
        </p>
      </div>
    )
  },
  {
    field: 'code',
    headerName: 'Code',
    width: 160, flex: 0.5,
    renderCell: (params) => (
      <div style={{width: "100%", display: "flex", gap: 8, alignItems: "center"}}>
        <p style={{width: "100%", wordBreak: "break-all", whiteSpace: "normal", marginBlock: "inherit"}}>
          {params.value}
        </p>
      </div>
    )
  },
  {
    field: 'group',
    headerName: 'Group',
    width: 160, flex: 0.5,
    renderCell: (params) => (
      <div style={{width: "100%", display: "flex", gap: 8, alignItems: "center"}}>
        <p style={{width: "100%", wordBreak: "break-all", whiteSpace: "normal", marginBlock: "inherit"}}>
          {params.value}
        </p>
      </div>
    )
  },
];

const channelCodesColumns = [
  {
    field: 'name', headerName: 'Name', width: 400, flex: 0.5,
    renderCell: (params) => (
      <div style={{ width: "100%", display: "flex", gap: 8, alignItems: "center" }}>
        <p style={{ width: "100%", wordBreak: "break-all", whiteSpace: "normal", marginBlock: "inherit" }}>
          {params.value}
        </p>
      </div>
    )
  },
  {
    field: 'code',
    headerName: 'Code',
    width: 160, flex: 0.5,
    renderCell: (params) => (
      <div style={{width: "100%", display: "flex", gap: 8, alignItems: "center"}}>
        <p style={{width: "100%", wordBreak: "break-all", whiteSpace: "normal", marginBlock: "inherit"}}>
          {params.value}
        </p>
      </div>
    )
  },
  {
    field: 'campaign_type',
    headerName: 'Group',
    width: 160, flex: 0.5,
    renderCell: (params) => (
      <div style={{width: "100%", display: "flex", gap: 8, alignItems: "center"}}>
        <p style={{width: "100%", wordBreak: "break-all", whiteSpace: "normal", marginBlock: "inherit"}}>
          {_.get(params, "value.name")}
        </p>
      </div>
    )
  },
];


const utmMediumAndSource = [
  {
    field: 'name', headerName: 'Name', width: 400, flex: 0.5,
    renderCell: (params) => (
      <div style={{ width: "100%", display: "flex", gap: 8, alignItems: "center" }}>
        <p style={{ width: "100%", wordBreak: "break-all", whiteSpace: "normal", marginBlock: "inherit" }}>
          {params.value}
        </p>
      </div>
    )
  },
  {
    field: 'code',
    headerName: 'Code',
    width: 160, flex: 0.5,
    renderCell: (params) => (
      <div style={{width: "100%", display: "flex", gap: 8, alignItems: "center"}}>
        <p style={{width: "100%", wordBreak: "break-all", whiteSpace: "normal", marginBlock: "inherit"}}>
          {params.value}
        </p>
      </div>
    )
  },
  {
    field: 'utm_type',
    headerName: 'Applicable Campaign Type(s)',
    width: 160, flex: 2,
    renderCell: (params) => (
      <div style={{width: "100%", display: "flex", gap: 8, alignItems: "center"}}>
        <p style={{width: "100%", wordBreak: "break-all", whiteSpace: "normal", marginBlock: "inherit"}}>
          {textListFormatter.format(_.map(params.value, (x)=>_.get(x, "utm_campaign_types_id.name")))}
        </p>
      </div>
    )
  }
];

const commonHeaders = [
  {
    field: 'name', headerName: 'Name', width: 400, flex: 0.5,
    renderCell: (params) => (
      <div style={{ width: "100%", display: "flex", gap: 8, alignItems: "center" }}>
        <p style={{ width: "100%", wordBreak: "break-all", whiteSpace: "normal", marginBlock: "inherit" }}>
          {params.value}
        </p>
      </div>
    )
  },
  {
    field: 'code',
    headerName: 'Code',
    width: 160, flex: 0.5,
    renderCell: (params) => (
      <div style={{width: "100%", display: "flex", gap: 8, alignItems: "center"}}>
        <p style={{width: "100%", wordBreak: "break-all", whiteSpace: "normal", marginBlock: "inherit"}}>
          {params.value}
        </p>
      </div>
    )
  }
];

const utmColumns = [
  { field: 'name', headerName: 'Full URL', width: 800, flex: 2,
  renderCell: (params) => (
    <div style={{width: "100%", display: "flex", gap: 8, alignItems: "center"}}>
      <IconButton
        color="primary"
        style={{  }}
        onClick={()=>copy(params.value)}
      >
        <Icon style={{fontSize: "14px"}}>file_copy</Icon>
      </IconButton>
      <p style={{width: "100%", wordBreak: "break-all", whiteSpace: "normal", marginBlock: "inherit"}}>
        {params.value}
      </p>
    </div>
  )  
},
  {
    field: 'shortUrl',
    headerName: 'Short URL',
    width: 260,
    flex: 0.5,
    renderCell: (params) => (
      <div style={{width: "100%", display: "flex", gap: 8, alignItems: "center"}}>
        <p style={{width: "100%", wordBreak: "break-all", whiteSpace: "normal", marginBlock: "inherit"}}>
          {params.value}
        </p>
      </div>
    )
  },
  {
    field: 'user',
    headerName: 'Email',
    width: 180,
    flex: 0.5,
    renderCell: (params) => (
      <div style={{width: "100%", display: "flex", gap: 8, alignItems: "center"}}>
        <p style={{width: "100%", wordBreak: "break-all", whiteSpace: "normal", marginBlock: "inherit"}}>
          {params.value}
        </p>
      </div>
    )
  }
];

const dataGridTestData = [
  {id: "1", advertiser: "z2", country: "IN", cultureCode: "en-gb", type: "paid", startDate: "2022-10-21", hub: "Manila", campaignName: "Etho oru campaign Name", finalCampaignName: "ithuthaan kadaisi name"},
  {id: "2", advertiser: "z2", country: "IN", cultureCode: "en-gb", type: "paid", startDate: "2022-10-21", hub: "Manila", campaignName: "Etho oru campaign Name", finalCampaignName: "ithuthaan kadaisi name"},
  {id: "3", advertiser: "z2", country: "IN", cultureCode: "en-gb", type: "paid", startDate: "2022-10-21", hub: "Manila", campaignName: "Etho oru campaign Name", finalCampaignName: "ithuthaan kadaisi name"},
  {id: "4", advertiser: "z2", country: "IN", cultureCode: "en-gb", type: "paid", startDate: "2022-10-21", hub: "Manila", campaignName: "Etho oru campaign Name", finalCampaignName: "ithuthaan kadaisi name"},
  {id: "5", advertiser: "z2", country: "IN", cultureCode: "en-gb", type: "paid", startDate: "2022-10-21", hub: "Manila", campaignName: "Etho oru campaign Name", finalCampaignName: "ithuthaan kadaisi name"},
  {id: "6", advertiser: "z2", country: "IN", cultureCode: "en-gb", type: "paid", startDate: "2022-10-21", hub: "Manila", campaignName: "Etho oru campaign Name", finalCampaignName: "ithuthaan kadaisi name"},
];
