/*eslint-disable*/
import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// @material-ui/core components
import {makeStyles} from "@material-ui/core/styles";
// @material-ui/icons
import AddAlert from "@material-ui/icons/AddAlert";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Button from "components/CustomButtons/Button.js";
import SnackbarContent from "components/Snackbar/SnackbarContent.js";
import Snackbar from "components/Snackbar/Snackbar.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import styled from "styled-components";
import ReactHtmlParser from "react-html-parser";
import {useLocation} from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import apiServices from "services/api/campaign.js";
import _ from "lodash";

const { getEaPages } = apiServices;

const styles = {
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "rgba(255,255,255,.62)",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color: "#FFFFFF"
        }
    },
    cardTitleWhite: {
        color: "#FFFFFF",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    }
};

const CardSnackBar = styled.div`
    color: #555555;
    padding: 20px 15px;
    position: relative;
    flex-wrap: unset;
    font-size: 14px;
    max-width: unset;
    min-width: unset;
    box-shadow: 0 12px 20px -10px rgb(255 255 255 / 28%), 0 4px 20px 0px rgb(0 0 0 / 12%), 0 7px 8px -5px rgb(255 255 255 / 20%);
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 300;
    line-height: 20px;
    border-radius: 3px;
    margin-bottom: 20px;
    background-color: #FFF;
    font-weight: 400;
`
const Tooltip = styled.span`
    visibility: hidden;
    max-width: 60%; 
    background-color: #8e24aa; 
    color: #ffffff; 
    text-align: center; 
    border-radius: 6px; 
    padding: 12px 12px; 
    position: absolute; 
    z-index: 1;
`
const Tooltiptext = styled.span`
    margin: 0 4px;
    &:hover ${Tooltip} {
        visibility: visible;
    }
    &:clicked ${Tooltip} {
        visibility: visible;
    }
`
const useStyles = makeStyles(styles);

export default function Notifications() {
    const classes = useStyles();
    const {pathname} = useLocation();
    const [tl, setTL] = React.useState(false);
    const [tc, setTC] = React.useState(false);
    const [tr, setTR] = React.useState(false);
    const [bl, setBL] = React.useState(false);
    const [bc, setBC] = React.useState(false);
    const [br, setBR] = React.useState(false);

    const {data:eaPages=[], status: campaignsListStatus} = useQuery(["eap-pages"], getEaPages);
    const [pathKey] = React.useState(_.snakeCase(_.last(pathname.split('/'))));
    const [pageTitle] = React.useState(_.startCase(pathKey));
    const [pageContent] = React.useState(_.get(eaPages, `eap_pages.[0].${pathKey}`, ""));

    React.useEffect(() => {
        // Specify how to clean up after this effect:
        return function cleanup() {
            // to stop the warning of calling setState of unmounted component
            var id = window.setTimeout(null, 0);
            while (id--) {
                window.clearTimeout(id);
            }
        };
    });
    const showNotification = place => {
        switch (place) {
            case "tl":
                if (!tl) {
                    setTL(true);
                    setTimeout(function () {
                        setTL(false);
                    }, 6000);
                }
                break;
            case "tc":
                if (!tc) {
                    setTC(true);
                    setTimeout(function () {
                        setTC(false);
                    }, 6000);
                }
                break;
            case "tr":
                if (!tr) {
                    setTR(true);
                    setTimeout(function () {
                        setTR(false);
                    }, 6000);
                }
                break;
            case "bl":
                if (!bl) {
                    setBL(true);
                    setTimeout(function () {
                        setBL(false);
                    }, 6000);
                }
                break;
            case "bc":
                if (!bc) {
                    setBC(true);
                    setTimeout(function () {
                        setBC(false);
                    }, 6000);
                }
                break;
            case "br":
                if (!br) {
                    setBR(true);
                    setTimeout(function () {
                        setBR(false);
                    }, 6000);
                }
                break;
            default:
                break;
        }
    };
    return (
        <Card>
            <CardHeader color="primary">
                <h4 className={classes.cardTitleWhite}>{pageTitle}</h4>
            </CardHeader>
            <CardBody>
                <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                        <SnackbarContent
                            message={pageContent}/>
                    </GridItem>
                </GridContainer>
            </CardBody>
        </Card>
    );
}
