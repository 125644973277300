import {
  successColor,
  whiteColor,
  grayColor,
  hexToRgb,
  dangerColor
} from "assets/jss/material-dashboard-react.js";

const dashboardStyle = (theme) => ({
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: "10px",
    flex: 1,
  },
  titleBar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      alignItems: "flex-start",
      "& h2": {
        fontSize: "2.5rem",
        paddingBlock: theme.spacing(1),
        fontWeight: 400,
      },
    },
  },
  titleBarActions: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    gap: 8,
    width: "30%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  titleBarActionSelect: {
    width: "100%",
  },
  buttonRed:{
    background:dangerColor[0],
    color:"white"
  },
  successText: {
    color: successColor[0]
  },
  upArrowCardCategory: {
    width: "16px",
    height: "16px"
  },
  stats: {
    color: grayColor[0],
    display: "flex",
    width:"100%",
    justifyContent:"space-between",
    fontSize: "12px",
    lineHeight: "22px",
    "& svg": {
      top: "4px",
      width: "16px",
      height: "16px",
      position: "relative",
      marginRight: "3px",
      marginLeft: "3px"
    },
    "& .fab,& .fas,& .far,& .fal,& .material-icons": {
      top: "4px",
      fontSize: "16px",
      position: "relative",
      marginRight: "3px",
      marginLeft: "3px"
    }
  },
  cardCategory: {
    color: grayColor[0],
    margin: "0",
    fontSize: "16px",
    marginTop: "0",
    paddingTop: "10px",
    marginBottom: "0",
    color: "#dc3224",
    fontWeight: 600
  },
  cardCategoryWhite: {
    color: "rgba(" + hexToRgb(whiteColor) + ",.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0"
  },
  cardTitle: {
    color: grayColor[2],
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: grayColor[1],
      fontWeight: "400",
      lineHeight: "1"
    }
  },
  cardTitleWhite: {
    color: whiteColor,
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: grayColor[1],
      fontWeight: "400",
      lineHeight: "1"
    }
  },
  cardFlag: {
    position: "absolute",
    width: 36,
    height: 56,
    borderRadius: "4px 4px 0px 0px",
    // boxShadow: "-1px 1px 4px 0px rgb(0 0 0 / 50%)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    background: "red",
    filter: "drop-shadow(0px 4px 4px rgba(0,0,0,0.5))",
    top: "-2px",
    right: "12px",
    zIndex: 5,
    cursor: "pointer",
    "&::before": {
      content: "''",
      width: 18, 
      height: 18,
      background: "linear-gradient(135deg, red 0%, red 50%, rgba(0,0,0,0) 50%, rgba(0,0,0,0) 100%)",
      position: "absolute",
      bottom: "-18px",
      left: 0,
      borderBottomLeftRadius: 6,
      // filter: "drop-shadow(0px 0px 4px rgba(0,0,0,0.5))",
    },
    "&::after": {
      content: "''",
      width: 18,
      height: 18,
      position: "absolute",
      bottom: "-18px",
      background:
        "linear-gradient(225deg, red 0%, red 50%, rgba(0,0,0,0) 50%, rgba(0,0,0,0) 100%)",
      right: 0,
      borderBottomRightRadius: 6,
      // filter: "drop-shadow(0px 0px 4px rgba(0,0,0,0.5))",

    }
  },
  popperPaper: {
    background: "white",
    border: "1px solid #e0e0e0",
    boxShadow: "0px 0px 2px 0px #cacaca",
    borderRadius: 8,
    padding: 8,
    zIndex: 10,
  },
  copyableText: {
    display: "flex",
    alignItems: "center",
    width: '100%',
    padding: theme.spacing(1.5),
    backgroundColor: theme.palette.grey[50],
    borderRadius: theme.shape.borderRadius,
    marginBottom: theme.spacing(1),
    "& .MuiIconButton-root": {
      marginLeft: "auto",
    },
  },
  monospace: {
    fontFamily: "monospace",
  }
});

export default dashboardStyle;
