import axios from "axios";

export const GCP_CAMPAIGNS_FILE_URL =
  "https://storage.googleapis.com/aa_ambassador/static-data/json/campaigns.json";

export const DIRECTUS_URL = "https://rad.airasiatech.com";

export const GCP_CATEGORIES_FILE_URL =
  "https://storage.googleapis.com/aa_ambassador/static-data/json/categories.json";

export const WP_URL =
  "https://us-central1-airasia-gamification-stg.cloudfunctions.net/wpRelay/index.php"; // "https://growth-wp.airasiatech.com/index.php";

export const WP_OG_URL = "https://growth-wp.airasiatech.com/index.php";

export const WC_API_USERNAME = "ck_9f5c26bed1223903e48ee3454068c9977663bca3";
export const WC_API_PASSWORD = "cs_e1ce54723bacd925e4108ba8f8d643f375aeaee7";

export const WP_API_USERNAME = "admin";
export const WP_API_PASSWORD = "sWOM WoNE Tf1u NtYu PJiC CYVE";

export const AFFILIATE_API_USERNAME = "cf2b2c429514aa29e0536a1b7981446a";
export const AFFILIATE_API_PASSWORD = "f8dc9dce0bee40878e0c386ae46cbcb6";

const wcApiId = `network - wc :-`;
const wpApiId = `network - wp :-`;

export { axios };

export const wcAxiosInstance = axios.create({
  baseURL: `${WP_URL}/wp-json/wc/v3`,
  auth: {
    username: WC_API_USERNAME,
    password: WC_API_PASSWORD,
  },
  responseType: "json",
});

wcAxiosInstance.interceptors.response.use(
  function (response) {
    return response.data;
  },
  function (error) {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      console.error(wcApiId, "Server response:", error.response.status);
      console.error(error.response.data);
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
      // http.ClientRequest in node.js
      console.log(wcApiId, "No response from server");
      console.log(error.request);
    } else {
      // Something happened in setting up the request that triggered an Error
      console.log(wcApiId, "Invalid request", error.message);
    }
    return Promise.reject(error);
  }
);

export const wpAxiosInstance = axios.create({
  baseURL: `${WP_URL}/wp-json/wp/v2`,
  auth: {
    username: WP_API_USERNAME,
    password: WP_API_PASSWORD,
  },
  responseType: "json",
});

wpAxiosInstance.interceptors.response.use(
  function (response) {
    return response.data;
  },
  function (error) {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      console.error(wpApiId, "Server response:", error.response.status);
      console.error(error.response.data);
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
      // http.ClientRequest in node.js
      console.log(wpApiId, "No response from server");
      console.log(error.request);
    } else {
      // Something happened in setting up the request that triggered an Error
      console.log(wpApiId, "Invalid request", error.message);
    }
    return Promise.reject(error);
  }
);
