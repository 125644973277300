const api_status = {
  active: "publish",
  inactive: "draft",
};

const display_status = {
  active: "Active",
  inactive: "Paused",
};

const wp_acf_keys = {
  short_description: "short_description",
  link: "link",
  embed: "embed",
  start_date: "start_date",
  end_date: "end_date",
  country_type: "country",
  campaign_thumbnail: "campaign_thumbnail",
  new_user_commission_type: "new_user_commission_type",
  new_user_commission_share: "new_user_commission_share",
  existing_user_commission_type: "existing_user_commission_type",
  existing_user_commission_share: "existing_user_commission_share",
  lob:"lob"
};

const wp_affiliate_keys = {
  commission: "_affwp_woocommerce_product_rate",
  commission_type: "_affwp_woocommerce_product_rate_type",
};

const visibility_plugin_key = "_alg_wc_pvbur_visible";

export default { api_status, display_status, wp_acf_keys, wp_affiliate_keys };
