import React, {useState, useEffect, useRef} from 'react';
// @material-ui/core components
import {makeStyles} from '@material-ui/core/styles';
// core components
import GridItem from 'components/Grid/GridItem.js';
import GridContainer from 'components/Grid/GridContainer.js';
import Button from 'components/CustomButtons/Button.js';
import Card from 'components/Card/Card.js';
import CardBody from 'components/Card/CardBody.js';
import {auth} from 'components/firebase';
import {Typography, TextField} from '@material-ui/core';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import {URLShortner} from 'components/Api';
import Icon from '@material-ui/core/Icon';
import copy from 'copy-to-clipboard';
import CardHeader from "../../components/Card/CardHeader";
import SnackbarContent from "../../components/Snackbar/SnackbarContent";
import styled from "styled-components";
import queryString from 'query-string';


function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const styles = {
    cardCategoryWhite: {
        color: 'rgba(255,255,255,.62)',
        margin: '0',
        fontSize: '14px',
        marginTop: '0',
        marginBottom: '0'
    },
    cardTitleWhite: {
        color: '#FFFFFF',
        marginTop: '0px',
        minHeight: 'auto',
        fontWeight: '300',
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: '3px',
        textDecoration: 'none'
    }
};

const Tooltip = styled.span`
    visibility: hidden;
    max-width: 60%; 
    background-color: #8e24aa; 
    color: #ffffff; 
    text-align: center; 
    border-radius: 6px; 
    padding: 12px 12px; 
    position: absolute; 
    z-index: 1;
`
const Tooltiptext = styled.span`
    margin: 0 4px;
    color: #8e24aa;
    &:hover ${Tooltip} {
        visibility: visible;
    }
    &:clicked ${Tooltip} {
        visibility: visible;
    }
`

const useStyles = makeStyles(styles);
const CopyStyle = {display: "inline-flex", gap: "24px", placeItems: 'center'};
export default function UserProfile() {
    const classes = useStyles();
    // const [User, setUser] = useState('');
    const [link, setlink] = useState(null);
    const AAlink = useRef();

    const [open, setopen] = useState(null);
    const [ValueRef, setvalueRef] = useState(null);
    const [snackBarText, setSnackBarText] = useState(null);
    const handleCloseSnack = () => {
        setopen(false);
    };
    // useEffect(() => {
    //     var q = queryString.parse(window.location.search)
    //     console.log("u",q);
    //     auth.onAuthStateChanged((users) => {
    //         const url = q?.url
    //         if (users) {
    //             var usr = `https://growth.airasiatech.com/admin/link?url=`
    //             setUser(usr);
    //             setvalueRef(url)
    //             URLShortner(usr + url, setlink)
    //             // setSnackBarText('URL Generated');
    //         }
    //     });
    // }, []);
    useEffect(() => {
        setSnackBarText('URL Generated');   
    }, [link])

    return (
        <div>
            <Snackbar
                open={open}
                autoHideDuration={3000}
                onClose={handleCloseSnack}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right'
                }}
            >
                <Alert onClose={handleCloseSnack} severity="success">
                    {snackBarText}
                </Alert>
            </Snackbar>
            <Card>
                <CardHeader color="primary">
                    <h4 className={classes.cardTitleWhite}>Create Shortcut for Unique link</h4>
                    <p className={classes.cardCategoryWhite}>
                    Whenever you are communicating with Allstar Ambassador share the Shortcut with them so that they can easily generate their Unique link
                    </p>
                </CardHeader>
                <CardBody>
                    <GridContainer>
                        <GridItem xs={12} sm={12} md={12}>
                            <h6 className={classes.cardCategory}> ENTER AIRASIA LANDING PAGE LINK TO GET SHORTCUT</h6>
                            <TextField
                                id="outlined-basic"
                                label={ValueRef?'':"Enter AirAsia Link"}
                                variant="outlined"
                                inputRef={AAlink}
                                style={{width: "100%"}}
                                value={ValueRef}
                            />
                            <br/>
                            <Button
                                onClick={() => {
                                    console.log(AAlink.current.value)
                                    if (AAlink.current.value) {
                                        var url= 'https://growth.airasiatech.com/admin/link?url='
                                        URLShortner(url +encodeURIComponent(AAlink.current.value), setlink);
                                        setvalueRef(null)
                                        setopen(true);
                                
                                    }
                                }}
                                variant="contained"
                                style={{backgroundColor: 'red', width: "100%", borderRadius: '4px'}}
                                color="secondary"
                                round
                            >
                                {ValueRef?'Edit':'Create Shortcut'}
                            </Button>
                            <div style={CopyStyle}>
                                <div style={{width: '80%'}}>
                                    {/* <h4 className={classes.cardTitle}>Flights</h4> */}
                                    <h4 className={classes.cardCategory}>{link ? link : "Generate link to copy"}</h4>
                                </div>
                                <div style={{position: 'relative'}}>
                                    <Button
                                        onClick={(e) => {
                                            copy(link);
                                            setSnackBarText('URL Copied');
                                            setopen(true);
                                            // console.log('open' + i);
                                        }}
                                        disabled={link ? false : true}
                                        style={{backgroundColor: 'red', borderRadius: '4px'}}
                                        round
                                    >
                                        <Icon>content_copy</Icon>
                                        <span>Copy</span>
                                    </Button>
                                </div>
                            </div>
                        </GridItem>
                    </GridContainer>
                </CardBody>
            </Card>
            {/* <Card>
                <CardBody>
                    <h4 style={{fontWeight: 'bold'}}>When to use Deep links?</h4>
                    <p>To send users to a custom
                        <Tooltiptext>landing page
                            <Tooltip>
                                Ex: <a style={{color: "white"}}
                                       href='https://www.airasia.com/food/my/en/restaurant/santan-mid-valley'>https://www.airasia.com/food/my/en/restaurant/santan-mid-valley</a>
                            </Tooltip>
                        </Tooltiptext>
                        in airasia you need to generate a deep link to get your unique link for that landing page.</p>
                    <h4 style={{fontWeight: 'bold'}}>How to generate Deep link?</h4>
                    <p>
                        <ol>
                            <li>Paste the
                                <Tooltiptext>landing page
                                    <Tooltip>
                                        Ex: <a style={{color: "white"}}
                                               href='https://www.airasia.com/food/my/en/restaurant/santan-mid-valley'>https://www.airasia.com/food/my/en/restaurant/santan-mid-valley</a>
                                    </Tooltip>
                                </Tooltiptext> in the deep link generator.
                            </li>
                            <li>Click on the 'Generate' CTA</li>
                            <li>A unique link will be generated</li>
                            <li>Copy the unique link to share it.</li>
                        </ol>
                    </p>
                </CardBody>
            </Card> */}
        </div>
    );
}
