import React from "react";

import { createTheme, ThemeProvider } from "@material-ui/core/styles";

const theme = createTheme({
  palette: {
    primary: {
      light: "#C92D21",
      main: "#DC3224",
      dark: "#aa261b",
      // contrastText: will be calculated to contrast with palette.primary.main
    },
    secondary: {
      light: "#EDEDF6",
      main: "#4B4FA6",
      dark: "4448A0",
      // contrastText: will be calculated to contrast with palette.secondary.main
    },
    // Used by `getContrastText()` to maximize the contrast between
    // the background and the text.
    contrastThreshold: 3,
    // Used by the functions below to shift a color's luminance by approximately
    // two indexes within its tonal palette.
    // E.g., shift from Red 500 to Red 300 or Red 700.
    tonalOffset: 0.2,
  },
});

const Theme = ({ children }) => {
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};

export default Theme;
