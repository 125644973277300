import React, { useState } from "react";
import PropTypes from "prop-types";
import { DataGrid } from "@material-ui/data-grid";
import { withStyles } from "@material-ui/core";

const StyledDataGrid = withStyles({
  root: {
      '& .MuiDataGrid-renderingZone': {
          maxHeight: 'none !important',
      },
      '& .MuiDataGrid-cell': {
          lineHeight: 'unset !important',
          maxHeight: 'none !important',
          whiteSpace: 'normal',
      },
      '& .MuiDataGrid-row': {
          maxHeight: 'none !important',
      },
  },
})(DataGrid);

const DataGridWrapper = (props) => {
  return (
    <div style={{ display: "flex", height: "auto" }}>
      <div style={{ flexGrow: 1 }}>
        <StyledDataGrid autoHeight {...props} disableSelectionOnClick />
      </div>
    </div>
  );
};

DataGridWrapper.propTypes = {
  /**
   * Array of objects for the header row, i.e. column names
   */
  columns: PropTypes.arrayOf(
    PropTypes.objectOf({
      field: PropTypes.string,
      headerName: PropTypes.string,
      description: PropTypes.string,
      width: PropTypes.number,
    })
  ).isRequired,
  /**
   * Array of objects with key value pairs where key is the column field
   */
  rows: PropTypes.arrayOf(PropTypes.object).isRequired,

  /**
   * The number of rows to show in a single page.
   */
  pageSize: PropTypes.number,

  /**
   * Height of the row in px
   */
  rowHeight: PropTypes.number,

  /**
   * Function to return row object on row click
   */
  onRowClick: PropTypes.func.isRequired,

  pagination: PropTypes.bool,
  // rowCount: PropTypes.number,
  // paginationMode: PropTypes.string,
  loading: PropTypes.bool,
  // onPageChange: PropTypes.func,
};

DataGridWrapper.defaultProps = {
  pageSize: 50,
  rowHeight: 50,
  pagination: true,
  loading: false,
};

export default DataGridWrapper;
