import {
  wcAxiosInstance,
  GCP_CATEGORIES_FILE_URL,
  axios,
  DIRECTUS_URL,
} from "../config/wp_api";

const getAll = () => {
  return wcAxiosInstance.get("/products/categories");
};

const getAllFromBucket = async () => {
  return axios.get(GCP_CATEGORIES_FILE_URL);
};

const getcategoryByIdFromDirectus = (id) => {
  return axios.get(`${DIRECTUS_URL}/items/lob/${id}`);
};

const getAllCategories = () => {
  return axios.get(`${DIRECTUS_URL}/items/lob`);
};

export default { getAll, getAllFromBucket, getcategoryByIdFromDirectus, getAllCategories };
