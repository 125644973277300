import { wpAxiosInstance, wcAxiosInstance } from "../config/wp_api";

const register = (firebaseUser) => {
  const additionalUserInfo = firebaseUser.additionalUserInfo;
  const user = firebaseUser.user;

  const signUpCredentials = {
    email: additionalUserInfo.profile.email,
    first_name: additionalUserInfo.profile.given_name,
    last_name: additionalUserInfo.profile.family_name,
    username: additionalUserInfo.profile.email,
    password: user.uid,
  };

  return wcAxiosInstance.post("/customers", signUpCredentials);
};

const login = async (email) => {
  return wpAxiosInstance.get("/users", {
    params: {
      context: "edit",
      search: email,
    },
  });
};

export default { register, login };
