import styled from "styled-components";

export const Page = styled.div`
  background-color: #ffffff;
  width: 100%;
  min-height: 100vh;
`;
export const FlexRowSetting = `
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;
export const Header = styled.div`
  display: flex;
  flex-direction: row;
  place-items: center;
  justify-content: space-between;
  background-color: #ffffff;
  height: 64px;
  padding: 0 48px;
  position: -webkit-stick;
  position: sticky;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1000;
  @media (max-width: 1024px) {
    position: fixed;
  }
  @media (max-width: 800px) {
    padding: 0 24px;
  }
  @media (max-width: 400px) {
    padding: 0px 12px;
  }
`;
export const HeaderFirstColumn = styled.div`
  ${FlexRowSetting}
  place-items: center;
  column-gap: 24px;
  @media (max-width: 480px) {
    column-gap: 12px;
  }
`;
export const HeaderSecondColumn = styled.div`
  ${FlexRowSetting}
  place-items: center;
  gap: 24px;
`;
export const HeaderLogo = styled.img`
  height: 68px;
  @media (max-width: 400px) {
    height: 40px;
  }
`;

export const Banner = styled.div`
  height: 200px;
  margin-top: 64px;
  width: 100%;
  background-color: #DC3224;
  color: #ffffff;
  padding: 16px;
  border-radius: 0px 0px 16px 16px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-around;

  & h5{
    font-size: 1.25rem;
    font-weight: 100;
  }
  & h4{
    font-size: 1.5rem;
  }
  & .MuiTypography-subtitle1{
    text-transform: lowercase;
  }
`;

export const BadgesContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 16px;
  padding: 16px;
`;

export const BadgeImg = styled.img`

`;

export const BadgeWithTitle = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  & img{
    width: 80px;
    height: 80px;
  }
  & p{
    text-align: center;
  }
`;
