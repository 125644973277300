import React, { useState, useEffect } from 'react';
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
// core components
import GridItem from 'components/Grid/GridItem.js';
import GridContainer from 'components/Grid/GridContainer.js';
import Button from 'components/CustomButtons/Button.js';
import Card from 'components/Card/Card.js';
import CardAvatar from 'components/Card/CardAvatar.js';
import CardBody from 'components/Card/CardBody.js';
import { auth } from 'components/firebase';
import { useHistory } from 'react-router-dom';

const styles = {
	cardCategoryWhite: {
		color: 'rgba(255,255,255,.62)',
		margin: '0',
		fontSize: '14px',
		marginTop: '0',
		marginBottom: '0'
	},
	cardTitleWhite: {
		color: '#FFFFFF',
		marginTop: '0px',
		minHeight: 'auto',
		fontWeight: '300',
		fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
		marginBottom: '3px',
		textDecoration: 'none'
	}
};

const useStyles = makeStyles(styles);

export default function UserProfile() {
	const classes = useStyles();
	const [ User, setUser ] = useState({});
	const history = useHistory();
	useEffect(() => {
		auth.onAuthStateChanged((users) => {
			if (users) {
				setUser(users);
			}
		});
	}, []);
	return (
		<div>
			<GridContainer>
				<GridItem xs={12} sm={12} md={4}>
					<Card profile>
						<CardAvatar profile>
							<a href="#pablo" onClick={(e) => e.preventDefault()}>
								<img src={User.photoURL} alt="..." />
							</a>
						</CardAvatar>
						<CardBody profile>
							<h6 className={classes.cardCategory}>{User.email}</h6>
							<h4 className={classes.cardTitle}>{User.displayName}</h4>

							<Button
								onClick={() => {
									auth.signOut();
									window.localStorage.clear();
									history.push('/');
								}}
								variant="contained"
				                style={{backgroundColor:"red", borderRadius: '4px'}}
								color="secondary"
								round
							>
								Logout
							</Button>
						</CardBody>
					</Card>
				</GridItem>
			</GridContainer>
		</div>
	);
}
