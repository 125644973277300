import React from "react";
import PropTypes from "prop-types";

import CircularProgress from "@material-ui/core/CircularProgress";

const Spinner = (props) => {
  const { color } = props;

  return <CircularProgress color={color} />;
};

Spinner.propTypes = {
  color: PropTypes.string,
};

Spinner.defaultProps = {
  color: "primary",
};

export default Spinner;
