import React, { createContext, useEffect, useReducer } from "react";
import _ from "lodash";
import { useQuery } from "@tanstack/react-query";
import apiServices from "services/api/campaign.js";

export const UserContext = createContext();


const { getCampaignViewerByEmail } = apiServices;


const initialState = {};

const getInitialState = () => {
    let temp = window.localStorage.getItem("aaWidget_userInfo");
    let tempJson = JSON.parse(temp);
    if(_.isPlainObject(tempJson)){
        return tempJson;
    }else{
        return {}
    }
};

const actions = {
  UPDATE_USER: "UPDATE_USER",
};

const reducer = (state, action) => {
  switch (action.type) {
    case actions.UPDATE_USER:{
      console.log("UPDATE USER -- ", _.get(action, "payload.isCampaignsViewer"))
      return _.cloneDeep(_.get(action, "payload", {}));
    }
    default:
      return state;
  }
};

const UserCtxProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, getInitialState());
  // const {data: campaignViewerData={}, status: campaignViewerDataStatus} = useQuery(["eap-user-login", _.get(state, "userInfo.username", "")], ()=>getCampaignViewerByEmail(_.get(state, "userInfo.username", "")));

  const value = {
    user: state,
    updateUser: (payload) => {
      let isAllstar = /(@airasia\.com)|(@groundteamred\.com)$/gi.test(
        _.get(payload, "userInfo.username", "")
      );
      // let isCampaignsViewer = !_.isUndefined(campaignViewerData) && !_.isEmpty(campaignViewerData.eap_users)
      dispatch({
        type: actions.UPDATE_USER,
        payload: { ...payload, isAllstar },
      });
    },
  };

  useEffect(() => {
    window.localStorage.setItem("aaWidget_userInfo", JSON.stringify(state));
  }, [state]);

  // useEffect(()=>{
  //   console.log("useEffectttt ---- ", campaignViewerData)
  //   if(!_.isUndefined(campaignViewerData) && !_.isEmpty(campaignViewerData.eap_users)){
  //     dispatch({type: actions.UPDATE_USER, payload: {...state, isCampaignsViewer: true}})
  //   }
  // }, [campaignViewerData])

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};

export default UserCtxProvider;
