import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useFormik } from "formik";
import * as yup from "yup";
import { Button, Grid, TextField } from "@material-ui/core";
import { Autocomplete } from '@material-ui/lab';
import { useQuery, useQueryClient } from "@tanstack/react-query";
import apiServices from "services/api/campaign.js";
import _ from "lodash";
import { DatePicker } from "@material-ui/pickers";
import { format, isValid } from "date-fns";
import { useToggle } from "utils/constants/hooks";
import copy from "copy-to-clipboard";
import SnackbarWrapper from "components/Molecules/Snackbar/Snackbar";
import queryString from "query-string";


const { getFieldOptionsForUtmFormFromDirectus, saveCampaignDetails, urlShortener } = apiServices;


// import { auth } from "../../components/firebase";

// 5CpXNyunbbGnnEx8cXAUPiPF9uj_Lh8H

const Container = styled.div`
  width: 100%;
  position: relative;
  // border: 4px solid red;
  @media (max-width: 1024px) {
    padding: 0;
  }
`;

const urlRegex = /([a-z]{1,2}tps?|airasia):\/\/((?:(?!(?:\/|#|\?|&)).)+)(?:(\/(?:(?:(?:(?!(?:#|\?|&)).)+\/))?))?(?:((?:(?!(?:\.|$|\?|#)).)+))?(?:(\.(?:(?!(?:\?|$|#)).)+))?(?:(\?(?:(?!(?:$|#)).)+))?(?:(#.+))?/gi;

const validationSchema = yup.object({
  url: yup.string("Enter URL").matches(urlRegex, "Invalid URL").required("URL is required"),
  medium: yup.string("Select Medium").nullable().required("Medium is required"),
  source: yup.string("Select Source").nullable().required("Source is required"),
  campaignName: yup
    .string("Select or Enter Campaign Name").nullable()
    .required("Campaign Name is required"),
  content: yup.string().nullable().optional("Medium is required"),
  term: yup.string().nullable().optional("Source is required"),
});

const AutoCompleteWithFormik = ({name="", label="", options=[], nameKey="", codeKey="", formik={}, disabled=false, isCodeKeySameAsnamekey=false, freeSolo=false}) => {
  
  return (
    <Autocomplete
      id={name}
      name={name}
      options={options}
      disabled={disabled}
      freeSolo={freeSolo}
      filterOptions={(options, state)=>{
        let newOptions = [];
        options.forEach((element) => {
          if (
            element[nameKey]
              .replace(",", "")
              .toLowerCase()
              .includes(state.inputValue.toLowerCase())
          )
            newOptions.push(element);
        });
        return newOptions;
      }}
      getOptionLabel={(option) => {
        const selectedOption = options.find(x => _.get(x, codeKey)===_.get(formik.values, name));
        if(selectedOption){
          return `${_.get(selectedOption, nameKey)}${isCodeKeySameAsnamekey ? null : `(${_.get(selectedOption, codeKey)})`}`
        }else{
          return "";
        }
      }}
      renderOption={(option) => (
        <div
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "space-between",
            alignItems: "baseline",
          }}
        >
          <span>{_.get(option, nameKey)}</span>
         {isCodeKeySameAsnamekey ? null : <span>{_.get(option, codeKey)}</span>}
        </div>
      )}
      value={formik.values[name]}
      getOptionSelected={(option, value) => _.get(option, codeKey)===value}
      onChange={(e, value) => {formik.setFieldTouched(name, true); formik.setFieldValue(name, _.get(value, codeKey));}}
      renderInput={(params) => <TextField label={label} error={formik.touched[name] && Boolean(formik.errors[name])}
      helperText={formik.touched[name] && formik.errors[name]} {...params} fullWidth variant="outlined" />}
    />
  );
};

const getDateStr = (date) => {
  if(isValid(date)){
    return format(date, "ddMMyyyy");
  }else{
    return "";
  }
};

const UtmNameForm = ({userEmail="", campaigns=[], campaignType:utmType = {}}) => {

  const queryClient = useQueryClient();

  const {data:fieldOptions={}, status} = useQuery(["utm-name-form-options", utmType], ()=>getFieldOptionsForUtmFormFromDirectus(utmType));
  const [isLoading, toggleIsLoading] = useToggle(false);
  const [finalUtmName, setFinalUtmName] = useState(null);
  const [finalShortUrl, setFinalShortUrl] = useState(null);
  const [isCompleted, setIsCompleted] = useState(false);
  const [snackMessage, setSnackMessage] = useState("");

  useEffect(()=>{
    if(finalUtmName){
      setIsCompleted(true);
    }
  }, [finalUtmName])

  const handleGenerate = async (values) => {
    toggleIsLoading();
    const {url, medium, source, campaignName, content, term} = values;
    let urlObject = new URL(url);
    const parsedUrlParams = queryString.parse(urlObject.search);
    parsedUrlParams.utm_medium = medium;
    parsedUrlParams.utm_source = source;
    parsedUrlParams.utm_content = content;
    parsedUrlParams.utm_term = term;
    parsedUrlParams.utm_campaign = campaignName;
    
    urlObject.search = queryString.stringify(parsedUrlParams, {skipNull: true});
    
    const formedUrl = urlObject.href;
    console.log("qs --- ", parsedUrlParams, formedUrl);

    const urlShortenerCallback =async (shortUrl, isError=false) => {
      const reqPayload = {
        name: formedUrl, user: userEmail, shortUrl
      };
      await saveCampaignDetails(reqPayload, "utm_names").then(resp => {
        console.log("resp from save -- ", resp);
        toggleIsLoading();
        setFinalUtmName(_.get(resp, "name"));
        if(!isError){
          setFinalShortUrl(shortUrl);
        }
        queryClient.invalidateQueries(['utm-names-list'])
      }).catch(err => {
        console.error("Error from save --- ", err);
        toggleIsLoading();
      });
    };

    await urlShortener(formedUrl, urlShortenerCallback, urlShortenerCallback)

  };

  console.log("fieldOptions -- ", fieldOptions);
  const {utm_medium: mediums=[], utm_source: sources=[] } = fieldOptions || {};



  const formik = useFormik({
    initialValues: {url: null, medium: null, source: null, campaignName: null, content: null, term: null},
    validationSchema: validationSchema,
    onSubmit: (values) => {
      console.log("values -- ", values);
      handleGenerate(values);
    },
  });

  return (
    <Container>
      <form onSubmit={formik.handleSubmit}>

        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              fullWidth
              id="url"
              name="url"
              label="URL"
              value={formik.values.url}
              disabled={isLoading || isCompleted}
              onChange={formik.handleChange}
              error={formik.touched.url && Boolean(formik.errors.url)}
              helperText={formik.touched.url && formik.errors.url}
            />
          </Grid>

          <Grid item xs={12}>
            <AutoCompleteWithFormik disabled={isLoading || isCompleted} name="campaignName" label="Campaign Name" formik={formik} options={campaigns} nameKey="name" codeKey="name" isCodeKeySameAsnamekey freeSolo />
          </Grid>
          <Grid item xs={12} sm={6}>
            <AutoCompleteWithFormik disabled={isLoading || isCompleted} name="medium" label="Medium" formik={formik} options={mediums} nameKey="name" codeKey="code" />
          </Grid>
          <Grid item xs={12} sm={6}>
            <AutoCompleteWithFormik disabled={isLoading || isCompleted} name="source" label="Source" formik={formik} options={sources} nameKey="name" codeKey="code" />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              variant="outlined"
              fullWidth
              id="content"
              name="content"
              label="Content"
              value={formik.values.content}
              disabled={isLoading || isCompleted}
              onChange={formik.handleChange}
              error={formik.touched.content && Boolean(formik.errors.content)}
              helperText={formik.touched.content && formik.errors.content}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              variant="outlined"
              fullWidth
              id="term"
              name="term"
              label="Term"
              value={formik.values.term}
              disabled={isLoading || isCompleted}
              onChange={formik.handleChange}
              error={formik.touched.term && Boolean(formik.errors.term)}
              helperText={formik.touched.term && formik.errors.term}
            />
          </Grid>

          {isCompleted ? <Grid item xs={12} sm={12}>
            <TextField
              variant="outlined"
              fullWidth
              id="fullUrl"
              name="fullUrl"
              label="Full URL"
              value={finalUtmName}
              InputLabelProps={{shrink: true}}
            />
          </Grid> : null}

          {isCompleted && !_.isEmpty(finalShortUrl) ? <Grid item xs={12} sm={12}>
            <TextField
              variant="outlined"
              fullWidth
              id="shortUrl"
              name="shortUrl"
              label="Short URL"
              value={finalShortUrl}
              InputLabelProps={{shrink: true}}
            />
          </Grid> : null}


          {/* <Grid item xs={12}> finalShortUrl
            <TextField
              variant="outlined"
              fullWidth
              // id="campaignName"
              // name="campaignName"
              label="Final Campaign Name"
            // value={formik.values.campaignName}
            // onChange={formik.handleChange}
            // error={formik.touched.campaignName && Boolean(formik.errors.campaignName)}
            // helperText={formik.touched.campaignName && formik.errors.campaignName}
            />
          </Grid> */}
          <Grid item xs={12} container style={{gap: 16}}>
            {
              isCompleted
                ? <>
                <Button color="primary" variant="outlined" {...(isCompleted ? {style:{background:"olivegreen"}, onClick: ()=>{copy(finalUtmName); setSnackMessage("Copied to Clipboard!")}} : {})} fullWidth type={isCompleted ? "button" : "submit"} size="large" disableElevation>
                Copy Full URL
              </Button>
              {
                _.isEmpty(finalShortUrl)
                ? null
                : <Button color="primary" variant="contained" {...(isCompleted ? {style:{background:"olivegreen"}, onClick: ()=>{copy(finalShortUrl); setSnackMessage("Copied to Clipboard!")}} : {})} fullWidth type={isCompleted ? "button" : "submit"} size="large" disableElevation>
                    Copy Short URL
                  </Button>
              }
                </>
                : <Button color="primary" variant="contained" disabled={isLoading} {...(isCompleted ? {style:{background:"olivegreen"}, onClick: ()=>{copy(finalUtmName); setSnackMessage("Copied to Clipboard!")}} : {})} fullWidth type={isCompleted ? "button" : "submit"} size="large" disableElevation>
                {isCompleted ? "Copy to Clipboard" : "Generate UTM"}
              </Button>
            }
          </Grid>
        </Grid>

      </form>
      <SnackbarWrapper show={!_.isEmpty(snackMessage)} onClose={()=>setSnackMessage("")} alertMessage={snackMessage}  />
    </Container>
  );
};

export default UtmNameForm;
