export const date_formats = {
  for_display: "dd/MM/yyyy",
  for_api: "yyyy-MM-ddThh:mm:ss",
};

export const user_roles_slugs = {
  Food: "food",
  Grocer: "grocer",
  Beauty: "beauty",
  Flights: "flights",
  Hotels: "hotels",
};

export const user_roles_labels = {
  food: "food",
  grocer: "grocer",
  beauty: "beauty",
  flights: "flights",
  hotels: "hotels",
};

export const lob_admin_roles = [
  "food",
  "grocer",
  "beauty",
  "flights",
  "hotels",
  "super",
];

export const admin_user_roles = ["editor", "administrator"];
export const non_admin_user_role = "customer";

export const userObjKey = "user";
export const userRoleKey = "userRoles";
export const categoryIdKey = "categoryIds";
export const allCategoryIdKey = "Allcategory";
