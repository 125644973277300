import React from "react";
import PropTypes from "prop-types";

import Backdrop from "@material-ui/core/Backdrop";

import Spinner from "components/Atoms/Spinner/Spinner";

import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: 999,
    color: "#fff",
  },
}));

const SpinnerOverlay = (props) => {
  const classes = useStyles();
  const { show, handleOnOverlayClick, spinnerColor } = props;

  return (
    <Backdrop
      classes={{ root: classes.backdrop }}
      open={show}
      onClick={handleOnOverlayClick}
    >
      <Spinner color={spinnerColor} />
    </Backdrop>
  );
};

SpinnerOverlay.propTypes = {
  show: PropTypes.bool,
  handleOnOverlayClick: PropTypes.func,
  spinnerColor: PropTypes.string,
};

SpinnerOverlay.defaultProps = {
  handleOnOverlayClick: () => {
    console.log("overlay clicked");
  },
  spinnerColor: "primary",
};

export default SpinnerOverlay;
