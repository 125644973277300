import { wpAxiosInstance, WP_OG_URL } from "../config/wp_api";

const create = async (file) => {
  var formdata = new FormData();
  formdata.append("file", file, file.name);
  return wpAxiosInstance.post("/media", formdata, {
    baseURL: `${WP_OG_URL}/wp-json/wp/v2`,
    "content-type": "multipart/form-data",
  });
};

export default { create };
