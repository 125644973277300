import React, { useEffect, useState, useContext } from "react";
import styled from "styled-components";
import { UserContext } from "UserContext";
import _ from "lodash";

const IFrame = styled.iframe`
  border: 0;
  margin: 0;
  width: 100%;
  height: 75vh;
  @media (max-width: 601px) {
    height: 100vh !important;
  }
`;
const Container = styled.div`
  width: 100%;
  position: relative;
  /* padding: 0 10%; */
  @media (max-width: 1024px) {
    padding: 0;
  }
`;
const HideDiv = styled.div`
  position: absolute;
  width: 100%;
  background: #eeeeee;
  bottom: 7px;
  height: 25px;
`;
export default function Analytics() {
  const { user = {} } = useContext(UserContext);
  const [paramsEmbed, setParamsEmbed] = useState(null);
  useEffect(() => {
    const email = _.get(user, "userInfo.username");
    if (!_.isEmpty(email)) {
      const username = email.split("@")[0];
      var width = typeof window !== "undefined" ? window.innerWidth : 418;
      if (width > 600)
        setParamsEmbed(
          "https://datastudio.google.com/embed/reporting/b851d272-5476-4d40-93a7-07934894fac9/page/l6FdB?params=%7B%22df12%22:%22include%25EE%2580%25800%25EE%2580%2580IN%25EE%2580%2580" +
            username +
            "%22%7D"
        );
      else
        setParamsEmbed(
          "https://datastudio.google.com/embed/reporting/86538992-fe45-4d58-ab72-f6a79b1d952a/page/l6FdB?params=%7B%22df12%22:%22include%25EE%2580%25800%25EE%2580%2580IN%25EE%2580%2580" +
            username +
            "%22%7D"
        );
    }
  }, [user]);
  const embedUrl = paramsEmbed;
  console.log(embedUrl);
  return (
    <Container>
      {paramsEmbed ? (
        <IFrame
          src={embedUrl}
          width="100%"
          height="100%"
          frameBorder="0"
          id="analytics-frame"
          allowFullScreen
        />
      ) : null}
      <HideDiv></HideDiv>
    </Container>
  );
}
