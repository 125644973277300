/*eslint-disable*/
import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// @material-ui/core components
import {makeStyles} from "@material-ui/core/styles";
// @material-ui/icons
import AddAlert from "@material-ui/icons/AddAlert";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Button from "components/CustomButtons/Button.js";
import SnackbarContent from "components/Snackbar/SnackbarContent.js";
import Snackbar from "components/Snackbar/Snackbar.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import styled from "styled-components";

const styles = {
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "rgba(255,255,255,.62)",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color: "#FFFFFF"
        }
    },
    cardTitleWhite: {
        color: "#FFFFFF",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    }
};

const CardSnackBar = styled.div`
    color: #555555;
    padding: 20px 15px;
    position: relative;
    flex-wrap: unset;
    font-size: 14px;
    max-width: unset;
    min-width: unset;
    box-shadow: 0 12px 20px -10px rgb(255 255 255 / 28%), 0 4px 20px 0px rgb(0 0 0 / 12%), 0 7px 8px -5px rgb(255 255 255 / 20%);
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 300;
    line-height: 20px;
    border-radius: 3px;
    margin-bottom: 20px;
    background-color: #FFF;
    font-weight: 400;
`
const Tooltip = styled.span`
    visibility: hidden;
    max-width: 60%; 
    background-color: #8e24aa; 
    color: #ffffff; 
    text-align: center; 
    border-radius: 6px; 
    padding: 12px 12px; 
    position: absolute; 
    z-index: 1;
`
const Tooltiptext = styled.span`
    margin: 0 4px;
    &:hover ${Tooltip} {
        visibility: visible;
    }
    &:clicked ${Tooltip} {
        visibility: visible;
    }
`
const useStyles = makeStyles(styles);

export default function Notifications() {
    const classes = useStyles();
    const [tl, setTL] = React.useState(false);
    const [tc, setTC] = React.useState(false);
    const [tr, setTR] = React.useState(false);
    const [bl, setBL] = React.useState(false);
    const [bc, setBC] = React.useState(false);
    const [br, setBR] = React.useState(false);
    React.useEffect(() => {
        // Specify how to clean up after this effect:
        return function cleanup() {
            // to stop the warning of calling setState of unmounted component
            var id = window.setTimeout(null, 0);
            while (id--) {
                window.clearTimeout(id);
            }
        };
    });
    const showNotification = place => {
        switch (place) {
            case "tl":
                if (!tl) {
                    setTL(true);
                    setTimeout(function () {
                        setTL(false);
                    }, 6000);
                }
                break;
            case "tc":
                if (!tc) {
                    setTC(true);
                    setTimeout(function () {
                        setTC(false);
                    }, 6000);
                }
                break;
            case "tr":
                if (!tr) {
                    setTR(true);
                    setTimeout(function () {
                        setTR(false);
                    }, 6000);
                }
                break;
            case "bl":
                if (!bl) {
                    setBL(true);
                    setTimeout(function () {
                        setBL(false);
                    }, 6000);
                }
                break;
            case "bc":
                if (!bc) {
                    setBC(true);
                    setTimeout(function () {
                        setBC(false);
                    }, 6000);
                }
                break;
            case "br":
                if (!br) {
                    setBR(true);
                    setTimeout(function () {
                        setBR(false);
                    }, 6000);
                }
                break;
            default:
                break;
        }
    };
    return (
        <Card>
            <CardHeader color="primary">
                <h4 className={classes.cardTitleWhite}>Rules Of Participation</h4>
            </CardHeader>
            <CardBody>
                <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                        <SnackbarContent
                            message={"1. <b>Unique Link</b>: Participating Allstar should use their unique link while sharing the LOB offers on Social media, emails, WhatsApp, or any other mediums. You can generate your unique link from <a href='https://growth.airasiatech.com/admin/link'>Deep Link generator</a> or <a href='https://growth.airasiatech.com/admin/dashboard'>Campaigns page</a>."}/>
                        <SnackbarContent
                            message={"2. <b>Promotions</b>: You are allowed to promote your unique link on any social media site, messaging platforms, or any other digital medium."}/>
                        <SnackbarContent
                            message={"3. <b>Cookie period</b> is 30 days. The Commission will be shared only when any user who came from the unique link makes a transaction within 30 days. " +
                            "<ul style='list-style-type: square;'>" +
                            "<li>Example 1: If you promoted airasia food on May 1st to your friend and a transaction is made on May 30th then a commission will be shared.</li>" +
                            "<li>Example 2: If your friend ordered food after May 31st then the transaction is not eligible for the commission.</li></ul>"}/>
                        <CardSnackBar>4. <b>Restrictions</b>: If you are buying traffic from Search engines (ex:Google, Bing, Yahoo, 7Search) then you should not bid on Brand keywords. To avoid any problem with this policy it's mandatory to add
                            <Tooltiptext>“airasia brand keywords”
                                <Tooltip>
                                    airasia.com, air asia, airasiaX, 亚洲航空, 亚航, 亚洲航空X, 亚航X, 亞洲航空, 亞航, 亞洲航空X, 亞航X, エア アジア, エアアジア, エア アジアX, エアアジアX, エア アジアエックス, エアアジアエックス, エア アジア エックス, エアアジア エックス, Ea Ajia, え ああ じ あ, え ああ じ あX, 에어아 시아, 에어아시아, 에어아 시아X, 에어아시아X, Zest, แอร์ เอเชีย, แอร์เอเชีย, แอร์ เอเชียX, แอร์เอเชียX, airaisa, air aisa, airsia, airasia, air asean, طيران اسيا, ai ra si a, rokki, airiasia, air ashia, air asaia, aiaasia, big click, bigclick, aiarasia, airasie, aira sia, אייר אסיה, aiirasia, ايراسيا, air.com asia, طيران اير اسيا, airasiia, طيران آسيا, air aasia, red hot seat sale, airazia, airasir, แอเอเชีย, แอรเอเชีย, えああじあ, эйр азия, аир азия, แอรืเอเชีย, アジアエア, эйразия, airアジア, エア・アジア, แอร์เอชีย, 亞州航空, แอร์-เอเชีย, แอรเอเซีย, แอเอเซีย, 에어아, สายการบินแอร์เอเซีย, 全亞洲, اير اسيا, snap, santan, airais, asia air, ourshop, airasi, zest, air ais, air asi, air adia, airasa, air asa, our shop, แอร์เอเซีย, aiasi, airas, ariasia, aisasia, airaia, airadi, air sia, airash, aur asi, air as, airaasi, ais asi, ai asi, air asua, aieasia, airasea, airsaia, เเอร์เอเชีย, แอร์เอ, aorasoa, air ashi, asiaair, air aia, air.asia, aurasia, air+asia, aor asia, airassia, airssia, aie asia, ari asia, airsasia, aorasia, air assia, air asoa, air aaia, aira asia, arasia
                                </Tooltip>
                            </Tooltiptext>
                             as negative keywords to your campaigns.</CardSnackBar>
                        <SnackbarContent
                            message={"5. <b>Payments Schedule</b>:Allstar gets the payment on the salary day i.e 26th of every month for the transactions that happened in the last month. " +
                            "<ul style='list-style-type: square;'><li>Example: If an Allstar generated a sale on Apr 30th then Allstar will receive the commission revenue in next month’s salary i.e. May 26th. "}/>
                        <SnackbarContent
                            message={"6. Rules can change at any time, please follow the official groups or site for the latest updates."}/>
                        <SnackbarContent
                            message={"7. For <b>iOS users</b>, please ensure you have allowed the App Tracking feature on the airasia Super App in order to track app activities for your commission. Below are the steps to enable App Tracking on iOS:-" +
                            "<ul style='list-style-type: square;'>" +
                            "<li>Step 1: Go to Settings.</li>" +
                            "<li>Step 2: Tap on Privacy & Settings.</li>" + 
                            "<li>Step 3: Tap on Tracking.</li>" + 
                            "<li>Step 4: Turn on Allow APPs to request tracking.</li>" + 
                            "<li>Step 5: Enable airasia or Launch airasia Super App and allow for tracking.</li>" + 
                            " </ul>"}/>
                    </GridItem>
                </GridContainer>
            </CardBody>
        </Card>
    );
}
