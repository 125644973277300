import React, { useState, useEffect } from "react";
import styled from "styled-components";

// @material-ui/core
import Button from "@material-ui/core/Button";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";

// Temporary Static Data
import DataGridData from "data/CampaignTable";

// Components
import DataGridWrapper from "components/Molecules/DataGrid/DataGrid";
import CampaignForm from "components/Molecules/CampaignForm/CampaignForm";

// API Requests
import Campaigns from "services/api/campaign";
import Media from "services/api/media";
import BQSync from "services/api/bqSync";

// Constants
import CampaignConstants from "utils/constants/campaign";
import Card from "@material-ui/core/Card";
import CardBody from "components/Card/CardBody.js";
import { categoryIdKey, userRoleKey } from "utils/constants/global";
import SpinnerOverlay from "components/Molecules/SpinnerOverlay/SpinnerOverlay";
import SnackbarWrapper from "components/Molecules/Snackbar/Snackbar";
import { Icon } from "@material-ui/core";
import { WP_OG_URL } from "services/config/wp_api";

const StyledButton = styled.div`
  margin-bottom: 2%;
`;

const CAMPAIGN_DATAGRID_DATA = {
  columns: [
    {
      field: "rowId",
      headerName: "ID",
      width: 150,
    },
    {
      field: "campaignName",
      headerName: "Campaign Name",
      width: 500,
    },
    {
      field: "status",
      headerName: "Status",
      width: 200,
    },
    {
      field: "lastUpdated",
      headerName: "Last Updated",
      type: "date",
      width: 200,
    },
  ],
  rows: [],
};

function CampaignList(props) {
  const { onNavigation, onRowClick } = props;
  const [data, setData] = useState(DataGridData);
  const [isLoading, setIsLoading] = useState(true);
  const [isVpnError, setIsVpnError] = useState(false);

  useEffect(() => {
    const userRoles = JSON.parse(window.localStorage.getItem(userRoleKey));
    const categoryMap = JSON.parse(window.localStorage.getItem(categoryIdKey));

    let categoryIds = "";
    for (let role of userRoles) {
      if (categoryMap[role] !== undefined) {
        categoryIds += categoryMap[role] + ",";
      }
    }

    Campaigns.getAll(
      { category: categoryIds },
      { baseURL: `${Campaigns.WP_OG_URL}/wp-json/wc/v3` }
    )
      .then((res) => {
        let rows = [];
        res.map((campaign, idx) => {
          rows.push({
            rowId: idx + 1,
            campaignName: campaign.name,
            status: campaign.status,
            lastUpdated: campaign.lastUpdated,
            ...campaign,
          });
        });
        CAMPAIGN_DATAGRID_DATA.rows = rows;
        setData(CAMPAIGN_DATAGRID_DATA);
        setIsLoading(false);
      })
      .catch((err) => {
        if (err.request && !err.response) {
          setIsVpnError(true);
          setIsLoading(false);
        }
      });
  }, []);

  if (isVpnError) {
    return (
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Icon style={{ fontSize: 40, marginRight: 8 }}>error</Icon>
        <h1>Please connect through AirAsia VPN to access this page</h1>
      </div>
    );
  }

  const onAddNewCampaignClick = () => {
    onNavigation();
  };

  const onDataGridRowClick = (event) => {
    const rowObject = event.row;
    console.log("row", rowObject);
    console.log(rowObject);

    const campaignFormData = {
      id: rowObject.id,
      title: rowObject.campaignName,
      shortDescription: rowObject.content,
      fullDescription: rowObject.more ? rowObject.more : "", // rich text edtior should not receive null prop
      embed: rowObject.embed ? rowObject.embed : "",
      imageUrl: rowObject.img,
      campaignLink: rowObject.link,
      campaignLob: rowObject.campaignLob,
      isActive:
        rowObject.status === CampaignConstants.display_status.active
          ? true
          : false,
      publishDate: rowObject.lastUpdated,
      newUserCommissionType: rowObject.newUserCommissionType,
      newUserCommissionShare: rowObject.newUserCommissionShare,
      existingUserCommissionType: rowObject.existingUserCommissionType,
      existingUserCommissionShare: rowObject.existingUserCommissionShare,
      countryType: rowObject.countryType,
      startDate: rowObject.startDate,
      endDate: rowObject.endDate,
    };

    onRowClick(campaignFormData);
  };

  return (
    <div>
      <h2>List of Campaigns</h2>
      <Card>
        <CardBody>
          <StyledButton>
            <Button
              variant="outlined"
              color="primary"
              onClick={onAddNewCampaignClick}
            >
              Add new
            </Button>
          </StyledButton>

          <SpinnerOverlay show={isLoading} />

          <DataGridWrapper {...data} onRowClick={onDataGridRowClick} />
        </CardBody>
      </Card>
    </div>
  );
}

const FORM_USAGE = {
  create_new: "new",
  update_existing: "edit",
};

const SNACKBAR_MESSAGES = {
  success: "Success!",
  error: "Oh no! An error occurred. Please try again",
};

function CampaignCRUD(props) {
  const [isLoading, setIsLoading] = useState(false);

  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState(
    SNACKBAR_MESSAGES.success
  );
  const [snackbarType, setSnackbarType] = useState("success");

  const { formData, onNavigation, usageType, lobList } = props;

  const { campaignFormTitle, campaignFormButtonText } = props.textElements;

  const afterNetworkResponse = (isSuccess) => {
    setIsLoading(false);

    if (isSuccess) {
      setSnackbarMessage(SNACKBAR_MESSAGES.success);
      setSnackbarType("success");
    } else {
      setSnackbarMessage(SNACKBAR_MESSAGES.error);
      setSnackbarType("error");
    }

    setShowSnackbar(true);
  };

  const onSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setShowSnackbar(false);
  };

  const handleDelete =
    usageType === FORM_USAGE.update_existing
      ? (id) => {
          setIsLoading(true);
          Campaigns.deleteSingle(id, { baseURL: `${WP_OG_URL}/wp-json/wc/v3` })
            .then(async () => {
              // await fetch(
              //   "https://us-central1-airasia-gamification-stg.cloudfunctions.net/fetchCampaigns"
              // );
              afterNetworkResponse(true);
              onNavigation();
            })
            .catch(() => {
              afterNetworkResponse(false);
            });
        }
      : null;

  const handleSubmitButton = (data) => {
    const sendData = (data) => {
      if (usageType === FORM_USAGE.create_new) {
        BQSync.update(null, data, lobList).then(() => {
          console.log("sync complete");
        });
        Campaigns.create(data, { baseURL: `${WP_OG_URL}/wp-json/wc/v3` })
          .then(async () => {
            // await fetch(
            //   "https://us-central1-airasia-gamification-stg.cloudfunctions.net/fetchCampaigns"
            // );
            afterNetworkResponse(true);
          })
          .catch((err) => {
            afterNetworkResponse(false);
          });
      } else {
        BQSync.update(formData, data, lobList).then(() => {
          console.log("sync complete");
        });
        Campaigns.update(data, { baseURL: `${WP_OG_URL}/wp-json/wc/v3` })
          .then(async () => {
            // await fetch(
            //   "https://us-central1-airasia-gamification-stg.cloudfunctions.net/fetchCampaigns"
            // );
            afterNetworkResponse(true);
          })
          .catch(() => {
            afterNetworkResponse(false);
          });
      }
    };

    setIsLoading(true);

    // upload image to WP if image exists and is edited from original
    if (data.uploadedImage && data.didEditImage) {
      Media.create(data.uploadedImage)
        .then((res) => {
          // replace imageUrl with WP image url
          const imageUrl = res.guid.raw;
          data.imageUrl = imageUrl;

          sendData(data);
        })
        .catch(() => {
          afterNetworkResponse(false);
        });
    } else {
      sendData(data);
    }
  };

  return (
    <div>
      <h2>{campaignFormTitle}</h2>
      <Card>
        <CardBody>
          <StyledButton>
            <Button variant="outlined" color="primary" onClick={onNavigation}>
              <KeyboardBackspaceIcon color="primary" />
            </Button>
          </StyledButton>
          {/* {isLoading && <CircularProgress />} */}
          <SpinnerOverlay show={isLoading} />

          <CampaignForm
            {...formData}
            lobList={lobList}
            campaignLob={formData.campaignLob}
            submitButtonText={campaignFormButtonText}
            onSubmit={(data) => {
              handleSubmitButton(data);
            }}
            onDelete={handleDelete}
          />
          <SnackbarWrapper
            show={showSnackbar}
            alertType={snackbarType}
            alertMessage={snackbarMessage}
            onClose={onSnackbarClose}
          />
        </CardBody>
      </Card>
    </div>
  );
}

function ComponentSwitch({ active, children }) {
  // Switch all children and return the "active" one
  return children.filter((child) => child.props.name == active);
}

const COMPONENT_NAME = {
  campaignList: "campaignList",
  campaignForm: "campaignForm",
};

const CF_CREATE_TEXT = {
  campaignFormTitle: "Add a new campaign",
  campaignFormButtonText: "Submit",
};

const CF_EDIT_TEXT = {
  campaignFormTitle: "Edit campaign",
  campaignFormButtonText: "Save changes",
};

export default function ManageCampaigns() {
  const [activeComponent, setActiveComponent] = useState(
    COMPONENT_NAME.campaignList
  );

  const [lobList, setLobList] = useState([]);

  const [campaignFormData, setCampaignFormData] = useState({});
  const [campaignFormTextElements, setCampaignFormTextElements] =
    useState(CF_CREATE_TEXT);

  const [formUsageType, setFormUsageType] = useState(FORM_USAGE.create_new);

  useEffect(() => {
    const categoryIdMap = JSON.parse(
      window.localStorage.getItem(categoryIdKey)
    );
    const lobList = [];

    for (let lobCategory of Object.keys(categoryIdMap)) {
      lobList.push({
        value: categoryIdMap[lobCategory],
        label: lobCategory,
      });
    }

    setLobList(lobList);
  }, []);

  return (
    <ComponentSwitch active={activeComponent}>
      <CampaignList
        name={COMPONENT_NAME.campaignList}
        onNavigation={() => {
          setActiveComponent(COMPONENT_NAME.campaignForm);
          setCampaignFormTextElements(CF_CREATE_TEXT);
          setCampaignFormData({});
        }}
        onRowClick={(campaignFormData) => {
          setActiveComponent(COMPONENT_NAME.campaignForm);
          setCampaignFormData(campaignFormData);
          setCampaignFormTextElements(CF_EDIT_TEXT);
          setFormUsageType(FORM_USAGE.update_existing);
        }}
      />

      <CampaignCRUD
        lobList={lobList}
        name={COMPONENT_NAME.campaignForm}
        onNavigation={() => {
          setActiveComponent(COMPONENT_NAME.campaignList);
          setFormUsageType(FORM_USAGE.create_new);
        }}
        textElements={campaignFormTextElements}
        formData={campaignFormData}
        usageType={formUsageType}
      />
    </ComponentSwitch>
  );
}
