import axios from "axios"
const printErrorText = (err) => {
  console.log("------------------------------------------");
  console.log("                                          ");
  console.log(" ███████ ██████  ██████   ██████  ██████  ");
  console.log(" ██      ██   ██ ██   ██ ██    ██ ██   ██ ");
  console.log(" █████   ██████  ██████  ██    ██ ██████  ");
  console.log(" ██      ██   ██ ██   ██ ██    ██ ██   ██ ");
  console.log(" ███████ ██   ██ ██   ██  ██████  ██   ██ ");
  console.log("                                          ");
  console.log("------------------------------------------");
  console.log(err);
  console.log("------------------------------------------");
}

const Shotner = axios.create({
    baseURL: "https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=AIzaSyBd6EHh8VLQR41vRL6IneeVn5g4tyEtlEc",
    headers: { "Content-Type": "application/json" } ,
    method:"POST"
  });

export const URLShortner = async (url,callback)=>{
    return await Shotner.post("",{
        
            "longDynamicLink": `https://airasia.page.link/?link=${encodeURIComponent(url)}&apn=com.airasia.android&ibi=com.airasia.ios`,
            "suffix": {
              "option": "SHORT"
            }
         
    }).then(resp=>{
        callback(resp.data.shortLink.replace("airasia.page.link","air.asia"))})
}