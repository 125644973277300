import React, { useState, useRef } from "react";
import PropTypes from "prop-types";

import styled from "styled-components";

import JoditEditor from "jodit-react";

const Container = styled.div`
  border-style: solid;
  border-color: rgba(0, 0, 0, 0.23);
  padding: 12px;
  border-width: 1px;
`;

const Label = styled.div`
  //   transform: translate(14px, -6px) scale(0.75);
  //   position: absolute;
  color: rgba(0, 0, 0, 0.54);
  padding: 0;
  font-size: 1rem;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  line-height: 1;
  letter-spacing: 0.00938em;
  padding: 10px 0px;
`;

const RichTextEditor = (props) => {
  const { htmlString, onChange, label } = props;
  const editor = useRef(null);
  const [content, setContent] = useState(htmlString);

  const config = {
    readonly: false, // all options from https://xdsoft.net/jodit/doc/
    uploader: { insertImageAsBase64URI: true },
    filebrowser: {
      showFoldersPanel: false,
      removeButtons: [
        "filebrowser.upload",
        "filebrowser.remove",
        "filebrowser.update",
        "filebrowser.select",
        "filebrowser.edit",
        "filebrowser.tiles",
        "filebrowser.list",
        "filebrowser.filter",
        "filebrowser.sort",
      ],
    },
    globalFullSize: false,
    enableDragAndDropFileToEditor: false,
  };

  return (
    <div>
      <Container>
        <Label>{label}</Label>

        <JoditEditor
          ref={editor}
          value={content}
          config={config}
          tabIndex={1} // tabIndex of textarea
          onBlur={(newContent) => {
            // console.log(newContent);
            setContent(newContent);
            onChange(newContent);
          }} // preferred to use only this option to update the content for performance reasons
          // onChange={(newContent) => {
          //   console.log(newContent);
          // }}
        />
      </Container>
    </div>
  );
};

RichTextEditor.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  htmlString: PropTypes.string,
  onChange: PropTypes.func,
};

RichTextEditor.defaultProps = {
  htmlString: "<p></p>",
};

export default RichTextEditor;
