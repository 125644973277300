import React from "react";
import PropTypes from "prop-types";

import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const SnackbarWrapper = (props) => {
  const { show, autoHideDuration, onClose, alertType, alertMessage } = props;

  return (
    <Snackbar open={show} autoHideDuration={autoHideDuration} onClose={onClose}>
      <Alert onClose={onClose} severity={alertType}>
        {alertMessage}
      </Alert>
    </Snackbar>
  );
};

SnackbarWrapper.propTypes = {
  show: PropTypes.bool,

  /**
   * Auto hide duration in ms
   */
  autoHideDuration: PropTypes.number,

  /**
   * function to hide the alert after the autoHideDuration
   */
  onClose: PropTypes.func,
  alertType: PropTypes.oneOf(["success", "error", "warning", "info"]),
  alertMessage: PropTypes.string,
};

SnackbarWrapper.defaultProps = {
  show: false,
  autoHideDuration: 3000,
  alertType: "success",
};

export default SnackbarWrapper;
