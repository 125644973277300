import axios from "axios";
import firebase from "firebase";
const delay = ms => new Promise(res => setTimeout(res, ms));

var firebaseConfig = {
  apiKey: "AIzaSyCGLff6cbIn5ZM_cbG2wh4JqV-JH7hgcDc",
  authDomain: "airasia-gaexport.firebaseapp.com",
  projectId: "airasia-gaexport",
  storageBucket: "airasia-gaexport.appspot.com",
  messagingSenderId: "751015570376",
  appId: "1:751015570376:web:d07178b55984cbd4f8670a",
};
firebase.initializeApp(firebaseConfig);
var uiConfig = {
  callbacks: {
    signInSuccessWithAuthResult: function (authResult, redirectUrl) {
      // User successfully signed in.
      // Return type determines whether we continue the redirect automatically
      // or whether we leave that to developer to handle.
      return false;
    },
  },
  signInFlow: "popup",
  // signInSuccessUrl: "/admin/dashboard",
  signInOptions: [firebase.auth.GoogleAuthProvider.PROVIDER_ID],
};
const auth = firebase.auth();

export { firebase, uiConfig, auth };
