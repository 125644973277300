/*!

=========================================================
* Material Dashboard React - v1.9.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/material-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
// @material-ui/icons
import Dashboard from "@material-ui/icons/Dashboard";
import Person from "@material-ui/icons/Person";
// core components/views for Admin layout
import DashboardPage from "views/Dashboard/Dashboard.js";
import ManageCampaignPage from "views/ManageCampaigns/ManageCampaigns";
import Analytics from "./views/Analytics/Analytics";
import UtmBuilder from "./views/UtmBuilder";
import UserProfile from "views/UserProfile/UserProfile.js";
import DeepLink from "views/DeepLink/deepLink.js";
import Link from "@material-ui/icons/Link";
import NotificationsPage from "views/Notifications/Notifications.js";
import EAPages from "views/EAPages";
import GavelIcon from "@material-ui/icons/Gavel";
import BarChartIcon from "@material-ui/icons/BarChart";
import BuildIcon from "@material-ui/icons/Build";
import { MenuBook } from "@material-ui/icons";
import UtmDictionary from "views/UtmDictionary";
// core components/views for RTL layout
// import RTLPage from "views/RTLPage/RTLPage.js";

export const AdminDashboardRoute = {
  path: "/manage-campaign",
  name: "Manage Campaigns",
  rtlName: "لوحة القيادة",
  icon: Dashboard,
  component: ManageCampaignPage,
  layout: "/admin",
};

export const DashboardRoutes = [
  {
    path: "/dashboard",
    name: "Campaigns",
    rtlName: "لوحة القيادة",
    icon: Dashboard,
    component: DashboardPage,
    layout: "/admin",
  },
  {
    path: "/rules",
    name: "Rules",
    rtlName: "ملف تعريفي للمستخدم",
    icon: GavelIcon,
    component: NotificationsPage,
    layout: "/admin",
  },
  // {
  //   path: "/user",
  //   name: "User Profile",
  //   rtlName: "ملف تعريفي للمستخدم",
  //   icon: Person,
  //   component: UserProfile,
  //   layout: "/admin",
  // },
  {
    path: "/link",
    name: "Deep Link",
    rtlName: "ملف تعريفي للمستخدم",
    icon: Link,
    component: DeepLink,
    layout: "/admin",
  },
  {
    path: "/analytics",
    name: "Analytics",
    rtlName: "ملف تعريفي للمستخدم",
    icon: BarChartIcon,
    component: Analytics,
    layout: "/admin",
  },
  {
    path: "/utm-builder",
    name: "UTM Builder",
    rtlName: "ملف تعريفي للمستخدم",
    icon: BuildIcon,
    component: UtmBuilder,
    layout: "/admin",
    isSpecialPermissionNeeded: true,
  },
  {
    path: "/utm-disctionary",
    name: "UTM Dictionary",
    rtlName: "ملف تعريفي للمستخدم",
    icon: MenuBook,
    component: UtmDictionary,
    layout: "/admin",
    isSpecialPermissionNeeded: true,
    isAnchorLink: false,
    href: "https://rad.airasiatech.com/admin/content"
  },
];

export const CampaignViewerDashboardRoutes = [
  {
    path: "/dashboard",
    name: "Campaigns",
    rtlName: "لوحة القيادة",
    icon: Dashboard,
    component: DashboardPage,
    layout: "/admin",
  },
  {
    path: "/announcements",
    name: "Announcements",
    rtlName: "ملف تعريفي للمستخدم",
    icon: GavelIcon,
    component: EAPages,
    layout: "/admin",
  },
  {
    path: "/commission-rates",
    name: "Commission Rates",
    rtlName: "ملف تعريفي للمستخدم",
    icon: Link,
    component: EAPages,
    layout: "/admin",
  },
  {
    path: "/terms-and-condition",
    name: "Terms & Conditions",
    rtlName: "ملف تعريفي للمستخدم",
    icon: Link,
    component: EAPages,
    layout: "/admin",
  },
];
