/* eslint-disable react/prop-types */
import React, { useState, useEffect, useContext } from "react";
// @material-ui/core
import Icon from "@material-ui/core/Icon";
import { makeStyles } from "@material-ui/core/styles";
import {
  Button,
  ClickAwayListener,
  MenuItem,
  Popper,
  TextField,
} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import copy from "copy-to-clipboard";
import _ from "lodash";
import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.js";
import useCountDown from "react-countdown-hook";
import {
  Snackbar,
  Dialog,
  AppBar,
  Toolbar,
  IconButton,
  Slide,
  Box,
  Tooltip,
  Chip,
  Avatar,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import RefreshIcon from "@material-ui/icons/Refresh";
import MuiAlert from "@material-ui/lab/Alert";
import CircularProgress from "@material-ui/core/CircularProgress";
import { URLShortner } from "components/Api";
import ReactHtmlParser from "react-html-parser";
import { WhatsApp, Telegram } from "@material-ui/icons";
import { allCategoryIdKey } from "utils/constants/global";
// API Requests
import campaigns from "services/api/campaign";
import { DIRECTUS_URL } from "services/config/wp_api";
import categories from "services/api/categories";
import axios from "axios";
import moment from "moment";
import { UserContext } from "UserContext";
import { useQuery } from "@tanstack/react-query";

const useStyles = makeStyles((theme) => styles(theme));

const CardStyle = {
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-start",
  flexDirection: "column",
  gap: "10px",
};
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const getDirectusAssetUrl = (assetID) => {
  // https://rad.airasiatech.com/assets/2eeab66b-eeeb-4863-9021-880154ee6f62?access_token=ZfRyCGaJQvVQdNKebtKvLEXSZmYovZEQ&width=3096&height=4128
  return `${DIRECTUS_URL}/assets/${assetID}?access_token=ZfRyCGaJQvVQdNKebtKvLEXSZmYovZEQ&width=200&height=200`;
};

const getDirectusAssetDownloadUrl = (assetID) => {
  // https://rad.airasiatech.com/assets/2eeab66b-eeeb-4863-9021-880154ee6f62?access_token=ZfRyCGaJQvVQdNKebtKvLEXSZmYovZEQ&width=3096&height=4128
  return `${DIRECTUS_URL}/assets/${assetID}?download&access_token=ZfRyCGaJQvVQdNKebtKvLEXSZmYovZEQ`;
};

// const getDirectusAssetThumbnailUrl = (assetID) => {
//   // https://rad.airasiatech.com/assets/2eeab66b-eeeb-4863-9021-880154ee6f62?access_token=ZfRyCGaJQvVQdNKebtKvLEXSZmYovZEQ&width=3096&height=4128
//   return `${DIRECTUS_URL}/assets/${assetID}?key=system_small_cover&access_token=ZfRyCGaJQvVQdNKebtKvLEXSZmYovZEQ&width=200&height=200`;
// };

function Dashboard() {
  console.log("renderinggggg");

  const [activeCampaigns, setActiveCampaigns] = useState([{}]);
  const [activeCategories, setActiveCategories] = useState([{}]);
  const [countries, setCountries] = useState([{}]);
  const [country, setCountry] = useState();
  const [lob, setLob] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const categoryMap = JSON.parse(window.localStorage.getItem(allCategoryIdKey));
  console.log("cat", categoryMap);
  // fetch campaigns from WP
  useEffect(() => {
    axios
      .all([
        campaigns.getAllActiveFromDirectus(),
        categories.getAllCategories(),
        campaigns.getAllCountriesFromDirectus(),
      ])
      .then((res) => {
        console.log("Campaign", res[0]);
        setActiveCampaigns(res[0]?.data?.data || []);
        setActiveCategories(res[1]?.data?.data || []);
        setCountries(res[2]?.data?.data?.meta?.options?.choices || []);
        setIsLoading(false);
      });
  }, []);

  useEffect(() => {
    // setIsLoading(true);
    campaigns
      .getAllActiveFromDirectus(lob, country)
      .then((res) => {
        setActiveCampaigns(res?.data?.data || []);
        setIsLoading(false);
      })
      .catch((err) => {
        console.error(err);
        setIsLoading(false);
      });
  }, [country, lob]);

  const classes = useStyles();
  const [drawer, setDrawer] = useState(false);
  const [learnMoreDrawer, setLearnMoreDrawer] = useState(false);
  const [open, setopen] = useState(null);
  const [pop, setPop] = useState(null);
  const [more, setMore] = useState(null);
  const [assets, setAssets] = useState([]);
  const handleCloseSnack = () => {
    setopen(false);
  };

  // return (
  //   <h1>HELLO</h1>
  // )

  return (
    <div>
      <Snackbar
        open={open}
        autoHideDuration={3000}
        onClose={handleCloseSnack}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <Alert onClose={handleCloseSnack} severity="success">
          Copied to Clipboard
        </Alert>
      </Snackbar>

      <div className={classes.titleBar}>
        <Typography variant="h2">Active Campaigns</Typography>
        <div className={classes.titleBarActions}>
          <TextField
            variant="outlined"
            size="small"
            label="LOB"
            className={classes.titleBarActionSelect}
            select
            displayEmpty
            onChange={(e) => {
              setIsLoading(true);
              setLob(e.target.value);
            }}
            renderValue={(value) => (value ? value : "All")}
            fullWidth
          >
            <MenuItem value={undefined}>All</MenuItem>
            {activeCategories.map((category) => (
              <MenuItem key={category.id} value={category.id}>
                {category.name}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            variant="outlined"
            size="small"
            label="Country"
            className={classes.titleBarActionSelect}
            select
            displayEmpty
            onChange={(e) => {
              setIsLoading(true);
              setCountry(e.target.value);
            }}
            renderValue={(value) => (value ? value : "All")}
            fullWidth
          >
            <MenuItem value={undefined}>All</MenuItem>
            {countries.map((country) => (
              <MenuItem key={country.value} value={country.value}>
                {country.text}
              </MenuItem>
            ))}
          </TextField>
        </div>
      </div>
      <GridContainer>
        {isLoading ? (
          <CircularProgress />
        ) : (
          activeCampaigns.map((x, i) => (
            <Cards
              key={"active" + i}
              x={x}
              i={i}
              classes={classes}
              setMore={setMore}
              setopen={setopen}
              setLearnMoreDrawer={setLearnMoreDrawer}
              setPop={setPop}
              setDrawer={setDrawer}
              setAssets={setAssets}
            />
          ))
        )}
      </GridContainer>
      <Dialog
        fullScreen
        open={learnMoreDrawer}
        onClose={() => {
          setLearnMoreDrawer(false);
          setAssets([]);
        }}
        TransitionComponent={Transition}
      >
        <AppBar className={classes.appBar} color="secondary">
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={() => {
                setLearnMoreDrawer(false);
              }}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              More Details
            </Typography>
          </Toolbar>
        </AppBar>
        <div style={{ margin: "20px" }}>
          {ReactHtmlParser(pop)}
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              width: "100%",
              gap: 16,
            }}
          >
            {assets.map((asset, j) => (
              <DownloadableAsset
                id={asset?.directus_files_id}
                key={`${asset?.id}____${j}`}
              />
            ))}
          </div>
        </div>
      </Dialog>
      <Dialog
        fullScreen
        open={drawer}
        onClose={() => setDrawer(false)}
        TransitionComponent={Transition}
      >
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={() => setDrawer(false)}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              Rules
            </Typography>
          </Toolbar>
        </AppBar>
        <div style={{ margin: "20px" }}>{ReactHtmlParser(more)}</div>
      </Dialog>
    </div>
  );
}

const getFileSizeString = (fileSize) => {
  let temp = (parseInt(fileSize) / (1000 * 1000)).toFixed(1);
  let tempArr = temp.split(".");
  if (tempArr[1] === "0") {
    return `${tempArr[0]} MB`;
  }
  return `${temp} MB`;
};

const DownloadableAsset = ({ id }) => {
  const [data, setData] = useState({});

  useEffect(() => {
    campaigns
      .getAssetDataById(id)
      .then((resp) => {
        setData(resp?.data?.data);
      })
      .catch((err) => {
        console.log("Error getting asset data for -- ", id, err);
      });
  }, []);

  return (
    <div
      style={{
        width: 160,
        height: 160,
        borderRadius: 8,
        overflow: "hidden",
        position: "relative",
        border: "1px solid #e0e0e0",
      }}
    >
      {data?.type?.includes("image") ? (
        <img
          src={getDirectusAssetUrl(data?.id)}
          alt={data?.title}
          style={{ width: "100%", height: "100%" }}
        />
      ) : (
        <div
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <span
            style={{
              fontSize: "40px",
              fontWeight: "bold",
              textTransform: "uppercase",
              letterSpacing: "4px",
              color: "red",
            }}
          >
            {data?.type?.split("/")[1]}
          </span>
        </div>
      )}

      <a
        style={{
          width: "100%",
          height: 48,
          background: "rgba(50, 50, 50, 0.9)",
          position: "absolute",
          right: 0,
          bottom: 0,
          display: "flex",
          alignItems: "center",
        }}
        href={getDirectusAssetDownloadUrl(data?.id)}
        target="_blank"
      >
        <div
          style={{
            width: "calc(100% - 48px)",
            height: 48,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            flexShrink: 1,
            paddingLeft: 8,
            alignItems: "center",
          }}
        >
          <span
            style={{
              width: "100%",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              color: "white",
              fontWeight: "bold",
              overflow: "hidden",
              fontSize: "14px",
            }}
          >
            {data?.title}
          </span>
          <span
            style={{
              width: "100%",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              color: "white",
              fontWeight: "normal",
              overflow: "hidden",
              fontSize: "12px",
              lineHeight: "12px",
            }}
          >
            {data?.type?.includes("image")
              ? `${data?.width} x ${data?.height}`
              : getFileSizeString(data?.filesize)}
          </span>
        </div>
        <div
          style={{
            width: 48,
            height: 36,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Icon style={{ color: "white" }}>file_download</Icon>
        </div>
      </a>
    </div>
  );
};

const getExpiryAsWords = (date) => {
  let momentDate = moment(date);
  if (momentDate.isValid()) {
    if (momentDate.isBefore(moment())) {
      return `Expired ${momentDate.fromNow()}`;
    }
    return `Expires ${momentDate.fromNow()}`;
  }
  return "";
};

const getExpiryTime = (endDate, countDownStartFxn, setIsExpiryCountdown) => {
  let expiryMoment = moment(endDate);
  let isValidMoment = expiryMoment.isValid();
  if (!isValidMoment) {
    return "";
  }
  // let isExpiryCrossedNow = expiryMoment.isBefore(moment());
  let diffInMilliSecs = expiryMoment.diff(moment());
  let isDiffLessThan24Hours = 0 < diffInMilliSecs && diffInMilliSecs < 86400000;
  // let islessThan24Hour = !isExpiryCrossedNow ? isDiffLessThan24Hours : false;

  if (isDiffLessThan24Hours) {
    setIsExpiryCountdown(true);
    countDownStartFxn(diffInMilliSecs);
    return true;
  }

  return getExpiryAsWords(endDate);

  // console.log(" ----------------------------------------- ", title);
  // console.log("expiryMoment ---- ", expiryMoment);
  // console.log("isValidMoment ---- ", isValidMoment);
  // console.log("isExpiryCrossedNow ---- ", isExpiryCrossedNow);
  // console.log("diffInMilliSecs ---- ", diffInMilliSecs);
  // console.log("islessThan24Hour ---- ", isDiffLessThan24Hours);
  // console.log(" ----------------------------------------- xxxxxxxxxxxxxxxxxxx -----------------------------------");
};

const convertMillisToTime = (millis) => {
  const padNumber = (x) => `${x}`.padStart(2, "0");
  return (
    padNumber(moment.duration(millis).hours()) +
    ":" +
    padNumber(moment.duration(millis).minutes()) +
    ":" +
    padNumber(moment.duration(millis).seconds())
  );
};

const Cards = ({
  x: cardData,
  i,
  classes = {},
  setMore = () => {},
  setopen = () => {},
  setLearnMoreDrawer = () => {},
  setPop = () => {},
  setDrawer = () => {},
  setAssets,
}) => {
  const { user = {} } = useContext(UserContext);
  const [cardLink, setCardLink] = useState(cardData?.link || null);

  const [lob, setLob] = useState({});
  const [campaignAssets, setCampaignAssets] = useState([]);
  const [isExpiryCountdown, setIsExpiryCountdown] = useState(false);
  const [timeLeft, { start, pause, resume, reset }] = useCountDown(
    cardData?.["end_time"],
    1000
  );
  const {
    data: couponsData = {},
    status: couponsDataStatus,
    refetch,
    isRefetching,
  } = useQuery(["campaign-coupons", user, cardData], () =>
    campaigns.getCouponsPerCampaign(
      _.get(cardData, "id"),
      _.get(user, "userInfo.username", "")
    )
  );
  const [couponGenLoading, setCouponGenLoading] = useState(false);

  const handleCouponGenerate = async () => {
    // console.log('clicked generate')
    setCouponGenLoading(true);
    await campaigns
      .generateCouponsForUserAndCampaign(
        _.get(cardData, "id"),
        _.get(user, "userInfo.username", "")
      )
      .then((res) => {
        console.log("coupon request submitted!", res);
        refetch();
        setCouponGenLoading(false);
      })
      .catch((err) => {
        console.error("coupon request failed!", err);
        setCouponGenLoading(false);
      });
  };

  useEffect(() => {
    if (_.get(user, "userInfo.username") && cardData?.link) {
      URLShortner(
        `https://prf.hn/click/camref:${_.get(
          cardData,
          "partnerize_campaign_ref",
          "1101lkapt"
        )}/ar:${_.get(user, "userInfo.username").replace(
          /@.*/,
          ""
        )}/destination:` + cardData?.link || "",
        (d) => {
          setCardLink(d);
          // let newdata = JSON.parse(JSON.stringify(camp));
          // newdata.url = d;
          // Arr.push(newdata);
        }
      );
    }
  }, [cardData?.link, user]);

  useEffect(() => {
    console.log("couponsData", couponsData);
  }, [couponsData]);

  useEffect(() => {
    categories
      .getcategoryByIdFromDirectus(cardData?.campaign_lob)
      .then((resp) => {
        setLob(resp?.data?.data);
      })
      .catch((err) => {
        console.error("Error fetching Campaign LOB -- ", err);
        setLob({});
      });
  }, [cardData?.lob]);

  useEffect(() => {
    campaigns.getAssetsForCampaignId(cardData.id).then((resp) => {
      console.log("resp from assets fetching --- ", resp);
      if (resp?.data?.data?.length > 0) {
        setCampaignAssets(resp?.data?.data);
      }
    });
    getExpiryTime(cardData.end_time, start, setIsExpiryCountdown);
  }, []);

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const open = Boolean(anchorEl);
  const popperId = open ? "simple-popper" : undefined;

  console.log("link", cardData);
  return (
    <GridItem xs={12} sm={12} md={4}>
      <Card chart style={{ height: "90%" }}>
        <CardHeader style={CardStyle}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <div
              style={{
                justifyContent: "flex-start",
                width: "100%",
                display: "flex",
                gap: "5px",
              }}
            >
              <span>
                {isExpiryCountdown
                  ? `Expires in ${convertMillisToTime(timeLeft)}`
                  : getExpiryAsWords(cardData?.["end_time"] || "")}
              </span>
              <IconButton
                aria-describedby={popperId}
                size="small"
                onClick={handleClick}
              >
                <Icon fontSize="small" color="primary">
                  info_outline
                </Icon>
              </IconButton>
            </div>
            <Popper
              style={{ zIndex: 10 }}
              id={popperId}
              open={open}
              anchorEl={anchorEl}
            >
              <ClickAwayListener onClickAway={() => setAnchorEl(null)}>
                <div className={classes.popperPaper}>
                  <b>Start Date :</b>{" "}
                  {moment(cardData?.["start_time"] || "").format(
                    "DD MMM YYYY, h:mm:ss a"
                  )}
                  <br />
                  <b>End Date :</b>{" "}
                  {moment(cardData?.["end_time"] || "").format(
                    "DD MMM YYYY, h:mm:ss a"
                  )}
                </div>
              </ClickAwayListener>
            </Popper>
            <div
              style={{
                justifyContent: "end",
                width: "100%",
                display: "flex",
                gap: "5px",
              }}
            >
              <Chip
                avatar={
                  <Avatar
                    alt="Category Logo"
                    src={getDirectusAssetUrl(lob?.image || "")}
                  />
                }
                label={lob?.name || "-"}
              />
              <Chip label={cardData?.country} />
              {cardData?.["priority"] && <div style={{ paddingInline: 16 }} />}
            </div>
          </div>
          <img
            src={getDirectusAssetUrl(cardData?.thumbnail)}
            style={{ height: "240px", width: "auto", borderRadius: "8px" }}
          />
          <h4
            style={{
              margin: "0 10px",
              textAlign: "center",
              fontWeight: 400,
              padding: "5px 10px",
            }}
          >
            {cardData.title}
          </h4>
        </CardHeader>
        {cardData["priority"] && (
          <Tooltip title={cardData?.["priority_name"] || "Priority Campaign"}>
            <div className={classes.cardFlag}>
              <Icon style={{ color: "white" }}>
                {cardData?.["priority_icon"] || "workspace_premium"}
              </Icon>
            </div>
          </Tooltip>
        )}
        <CardBody
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "end",
            paddingTop: 0,
          }}
        >
          <Box
            style={{
              display: "flex",
              justifyContent: "end",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <div style={{ marginBlock: 8 }}>
              <p
                style={{ fontWeight: "500", fontSize: "20px" }}
                className={classes.cardCategory}
              >
                {cardData?.description}
              </p>
            </div>

            {_.isEmpty(couponsData) ||
            _.get(couponsData, "0.coupon_status") === "requested" ? (
              <Box>
                <Button
                  variant="contained"
                  color="primary"
                  disableElevation
                  onClick={handleCouponGenerate}
                  disabled={
                    couponGenLoading ||
                    _.get(couponsData, "0.coupon_status") === "requested"
                  }
                >
                  {_.get(couponsData, "0.coupon_status") === "requested"
                    ? "In Process"
                    : "Generate Coupon"}
                </Button>
                {_.get(couponsData, "0.coupon_status") === "requested" ? (
                  <IconButton onClick={refetch}>
                    <RefreshIcon fontSize="small" />
                  </IconButton>
                ) : null}
              </Box>
            ) : (
              <div className={classes.copyableText}>
                <Typography variant="body2" className={classes.monospace}>
                  {_.get(couponsData, "0.coupon_code")}
                </Typography>
                <IconButton
                  size="small"
                  onClick={() => {
                    copy(_.get(couponsData, "0.coupon_code"));
                    setopen(true);
                  }}
                  aria-label="Copy link"
                >
                  <FileCopyIcon fontSize="small" />
                </IconButton>
              </div>
            )}

            {/* <div style={{ position: "relative" }}>
              {_.isEmpty(couponsData) ||
              _.get(couponsData, "0.coupon_status") === "requested" ? (
                <Button
                  variant="contained"
                  color="primary"
                  disableElevation
                  onClick={handleCouponGenerate}
                  disabled={
                    couponGenLoading ||
                    _.get(couponsData, "0.coupon_status") === "requested"
                  }
                >
                  {_.get(couponsData, "0.coupon_status") === "requested"
                    ? "In Process"
                    : "Generate Coupon"}
                </Button>
              ) : (
                <Button
                  onClick={() => {
                    // handleClick(e);
                    copy(_.get(couponsData, "0.coupon_code"));
                    setopen(true);
                    // console.log('open' + i);
                  }}
                >
                  {cardLink ? (
                    <Input
                      id="standard"
                      label="Click here to copy your coupon code"
                      value={_.get(couponsData, "0.coupon_code")}
                      endAdornment={
                        <InputAdornment position="end">
                          <Icon>content_copy</Icon>
                        </InputAdornment>
                      }
                      aria-describedby="Copy Link"
                    />
                  ) : null}
                </Button>
              )}
            </div> */}

            {cardLink && (
              <div className={classes.copyableText}>
                <Typography variant="body2" className={classes.monospace}>
                  {cardLink}
                </Typography>
                <IconButton
                  size="small"
                  onClick={() => {
                    copy(cardLink);
                    setopen(true);
                  }}
                  aria-label="Copy link"
                >
                  <FileCopyIcon fontSize="small" />
                </IconButton>
              </div>
            )}

            {/* <div style={{ position: "relative" }}>
              <Button
                aria-describedby={"popover" + i}
                onClick={() => {
                  // handleClick(e);
                  copy(cardLink);
                  setopen(true);
                  // console.log('open' + i);
                }}
              >
                {cardLink ? (
                  <Input
                    id="standard"
                    value={cardLink}
                    endAdornment={
                      <InputAdornment position="end">
                        <Icon>content_copy</Icon>
                      </InputAdornment>
                    }
                    aria-describedby="Copy Link"
                  />
                ) : null}
              </Button>
            </div> */}
          </Box>
        </CardBody>
        {/* <div style={{flexGrow:1}} ></div> */}
        <CardFooter chart>
          <Box
            style={{
              display: "flex",
              justifyContent: "space-around",
              alignItems: "center",
            }}
          >
            {/* <span>SHARE:</span> */}
            {[
              {
                title: "Share on Whatsapp",
                icon: WhatsApp,
                link: `
                https://api.whatsapp.com/send/?text=${cardLink || ""}%0A%0A${
                  cardData?.description || ""
                }`,
              },
              {
                title: "Share on Telegram",
                icon: Telegram,
                link: `https://t.me/share/url?url=${cardLink}&text=${
                  cardData?.description || ""
                }`,
              },
              // { 'title': "FaceBook Share", icon: Facebook, link: "" }, https://t.me/share/url?url={url}&text={text}
              // { 'title': "Whatsapp Share", icon: Instagram, link: "" }
            ].map((x, i) => (
              <Tooltip title={x.title} key={"share" + i}>
                <IconButton
                  aria-label="delete"
                  size="medium"
                  style={{ transform: "scale(1.5)" }}
                  onClick={() =>
                    window.open(
                      x.link,
                      "_blank" // <- This is what makes it open in a new window.
                    )
                  }
                >
                  {<x.icon />}
                </IconButton>
              </Tooltip>
            ))}
          </Box>
          <div
            className={classes.stats}
            style={{ justifyContent: "end", gap: "10px" }}
          >
            {cardData?.rules && (
              <Button
                variant="contained"
                className={classes.buttonRed}
                color="secondary"
                disableElevation
                onClick={() => {
                  setMore(cardData?.rules);
                  setDrawer(true);
                }}
              >
                Rules
              </Button>
            )}
            {cardData?.details && (
              <Button
                variant="contained"
                // className={classes.buttonRed}
                color="default"
                disableElevation
                onClick={() => {
                  setMore(cardData?.rules);
                  setPop(cardData?.details);
                  setAssets([...campaignAssets]);
                  setLearnMoreDrawer(true);
                }}
              >
                Learn More
              </Button>
            )}
          </div>
        </CardFooter>
      </Card>
    </GridItem>
  );
};

// Dashboard.whyDidYouRender = true;
export default Dashboard;
