import React, { useState, useEffect, useContext } from "react";
import styled from "styled-components";
import { Button, Drawer, Grid, TextField, Typography, Container, IconButton, Icon, Menu, MenuItem, Paper, Tabs, Tab, TabPanel} from "@material-ui/core";
import DataGridWrapper from "components/Molecules/DataGrid/DataGrid";
import { useToggle } from "utils/constants/hooks";
import CampaignForm from "./CampaignForm";
import { useQuery } from "@tanstack/react-query";
import apiServices from "services/api/campaign.js";
import { auth } from 'components/firebase';
import _ from "lodash";
import UtmNameForm from "./UtmNameForm";
import { UserContext } from "UserContext";
import copy from "copy-to-clipboard";
import SnackbarWrapper from "components/Molecules/Snackbar/Snackbar";



const { getUtmCampaignTypesFromDirectus, getCampaignsByUser } = apiServices;

const StyledContainer = styled.div`
  width: 100%;
  position: relative;
  // border: 4px solid red;
  @media (max-width: 1024px) {
    padding: 0;
  }
`;

const UtmBuilder = () => {
  const {user={}} = useContext(UserContext);
  const [selectedCampaignType, setSelectedCampaignType] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const [menuType, setMenuType] = useState('campaign')
  // const [user, setUser] = useState({});

  const [tabValue, setTabValue] = React.useState(0);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const {data:campaignsList=[], status: campaignsListStatus} = useQuery(["campaigns-list", user], ()=>getCampaignsByUser(_.get(user, "userInfo.username", "")));
  const {data:utmNamesList=[], status: utmNamesListStatus} = useQuery(["utm-names-list", user], ()=>getCampaignsByUser(_.get(user, "userInfo.username", ""), true));


  const handleClickCampaignGeneration = (event, currentMenuType='campaign') => {
    setAnchorEl(event.currentTarget);
    setMenuType(currentMenuType);
  };

  const handleCloseCampaignGeneration = () => {setSelectedCampaignType(""); handleClose();};

  const handleClose = () => {
    setAnchorEl(null);
  };

  const {data:campaignTypes=[], status} = useQuery(["campaign-types"], getUtmCampaignTypesFromDirectus);

  // console.log("data from getUtm -- ", campaignTypes);

  return (
    <StyledContainer>
      <Grid container spacing={2} style={{paddingBlock: 8, paddingBottom: 0, marginBottom: 16}}>
        <Grid item xs={12} sm={6} container justifyContent="flex-start" alignItems="center">
          {/* <Typography variant="h5">Campaigns List</Typography> */}
          {/* <Paper square> */}
            <Tabs
              value={tabValue}
              indicatorColor="primary"
              textColor="primary"
              onChange={handleTabChange}
              aria-label="disabled tabs example"
            >
              <Tab label="Campaigns" />
              <Tab label="UTM" />
            </Tabs>
          {/* </Paper> */}
        </Grid>
        <Grid item xs={12} sm={6} container justifyContent="flex-end" style={{gap: 8}}>
          <Button variant="outlined" color="primary" disableElevation onClick={e=>handleClickCampaignGeneration(e, "campaign")}>
            Generate Campaign Name
          </Button>
          <Button variant="outlined" color="primary" disableElevation /* onClick={()=>setSelectedCamapaignType("utmBuilder")} */ onClick={e=>handleClickCampaignGeneration(e, "utm")}>
            Generate UTM
          </Button>
          <Menu
            id="generate-campaign-name-selection-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
          > 
          {
            _.orderBy(campaignTypes, "order").filter(x => _.isEqual(_.get(x, "type"), menuType)).map((x,i) => <MenuItem key={`generate-campaign-name-option-${_.get(x, "id", i)}`} onClick={()=>setSelectedCampaignType(_.get(x, "id"))}>{_.get(x, "name", "")}</MenuItem>)
          }
          </Menu>
        </Grid>
      </Grid>
      {
        tabValue===0
          ? <DataGridWrapper columns={columns} rows={_.get(campaignsList, "utm_campaigns", [])} />
          : tabValue===1
            ? <DataGridWrapper columns={utmColumns} rows={_.get(utmNamesList, "utm_names", [])}/>
            : null
      }
      
      <Drawer open={Boolean(selectedCampaignType)} onClose={handleCloseCampaignGeneration} anchor="right" disableEscapeKeyDown onClose={(e,reason) => {if(reason==="backdropClick"){return null}}}>
        <Container maxWidth="md">
          <Grid container spacing={2} style={{paddingBlock: 16, paddingBottom: 0, marginBottom: 32, borderBottom: "1px solid #dfdfdf"}}>
            <Grid item xs={10} container justifyContent="flex-start" alignItems="center">
              <Typography variant="h6" style={{textTransform: "none", fontSize: "24px"}}>Generate {menuType==="utm" ? "UTM" : `${_.capitalize(_.get(_.find(campaignTypes, x => x["id"]===selectedCampaignType), "code", "paid"))} Campaign Name`}</Typography>
            </Grid>
            <Grid item xs={2} container justifyContent="flex-end">
              <IconButton onClick={handleCloseCampaignGeneration}>
                <Icon>close</Icon>
              </IconButton>
            </Grid>
          </Grid>
          {
            menuType==="utm"
              ? <UtmNameForm campaignType={selectedCampaignType} userEmail={_.get(user, "userInfo.username", "")} campaigns={_.get(campaignsList, "utm_campaigns", [])}/>
              : <CampaignForm campaignType={selectedCampaignType} campaignTypeCode={_.get(_.find(campaignTypes, x => x["id"]===selectedCampaignType), "code", "paid")} userEmail={_.get(user, "userInfo.username", "")} />
          }
        </Container>
      </Drawer>
    </StyledContainer>
  );
};

export default UtmBuilder;

const columns = [
  {
    field: 'name', headerName: 'Campaign Name', width: 400, flex: 2,
    renderCell: (params) => (
      <div style={{ width: "100%", display: "flex", gap: 8, alignItems: "center" }}>
        <IconButton
          color="primary"
          style={{}}
          onClick={() => copy(params.value)}
        >
          <Icon style={{ fontSize: "14px" }}>file_copy</Icon>
        </IconButton>
        <p style={{ width: "100%", wordBreak: "break-all", whiteSpace: "normal", marginBlock: "inherit" }}>
          {params.value}
        </p>
      </div>
    )
  },
  {
    field: 'startDate',
    headerName: 'StartDate',
    width: 160, flex: 0.5,
    renderCell: (params) => (
      <div style={{width: "100%", display: "flex", gap: 8, alignItems: "center"}}>
        <p style={{width: "100%", wordBreak: "break-all", whiteSpace: "normal", marginBlock: "inherit"}}>
          {params.value}
        </p>
      </div>
    )
  },
  {
    field: 'endDate',
    headerName: 'End Date',
    width: 160, flxe: 0.5,
    renderCell: (params) => (
      <div style={{width: "100%", display: "flex", gap: 8, alignItems: "center"}}>
        <p style={{width: "100%", wordBreak: "break-all", whiteSpace: "normal", marginBlock: "inherit"}}>
          {params.value}
        </p>
      </div>
    )
  },
  {
    field: 'user',
    headerName: 'Email',
    width: 200, flex: 0.5,
    renderCell: (params) => (
      <div style={{width: "100%", display: "flex", gap: 8, alignItems: "center"}}>
        <p style={{width: "100%", wordBreak: "break-all", whiteSpace: "normal", marginBlock: "inherit"}}>
          {params.value}
        </p>
      </div>
    )
  },
];

const utmColumns = [
  { field: 'name', headerName: 'Full URL', width: 800, flex: 2,
  renderCell: (params) => (
    <div style={{width: "100%", display: "flex", gap: 8, alignItems: "center"}}>
      <IconButton
        color="primary"
        style={{  }}
        onClick={()=>copy(params.value)}
      >
        <Icon style={{fontSize: "14px"}}>file_copy</Icon>
      </IconButton>
      <p style={{width: "100%", wordBreak: "break-all", whiteSpace: "normal", marginBlock: "inherit"}}>
        {params.value}
      </p>
    </div>
  )  
},
  {
    field: 'shortUrl',
    headerName: 'Short URL',
    width: 260,
    flex: 0.5,
    renderCell: (params) => (
      <div style={{width: "100%", display: "flex", gap: 8, alignItems: "center"}}>
        <p style={{width: "100%", wordBreak: "break-all", whiteSpace: "normal", marginBlock: "inherit"}}>
          {params.value}
        </p>
      </div>
    )
  },
  {
    field: 'user',
    headerName: 'Email',
    width: 180,
    flex: 0.5,
    renderCell: (params) => (
      <div style={{width: "100%", display: "flex", gap: 8, alignItems: "center"}}>
        <p style={{width: "100%", wordBreak: "break-all", whiteSpace: "normal", marginBlock: "inherit"}}>
          {params.value}
        </p>
      </div>
    )
  }
];

const dataGridTestData = [
  {id: "1", advertiser: "z2", country: "IN", cultureCode: "en-gb", type: "paid", startDate: "2022-10-21", hub: "Manila", campaignName: "Etho oru campaign Name", finalCampaignName: "ithuthaan kadaisi name"},
  {id: "2", advertiser: "z2", country: "IN", cultureCode: "en-gb", type: "paid", startDate: "2022-10-21", hub: "Manila", campaignName: "Etho oru campaign Name", finalCampaignName: "ithuthaan kadaisi name"},
  {id: "3", advertiser: "z2", country: "IN", cultureCode: "en-gb", type: "paid", startDate: "2022-10-21", hub: "Manila", campaignName: "Etho oru campaign Name", finalCampaignName: "ithuthaan kadaisi name"},
  {id: "4", advertiser: "z2", country: "IN", cultureCode: "en-gb", type: "paid", startDate: "2022-10-21", hub: "Manila", campaignName: "Etho oru campaign Name", finalCampaignName: "ithuthaan kadaisi name"},
  {id: "5", advertiser: "z2", country: "IN", cultureCode: "en-gb", type: "paid", startDate: "2022-10-21", hub: "Manila", campaignName: "Etho oru campaign Name", finalCampaignName: "ithuthaan kadaisi name"},
  {id: "6", advertiser: "z2", country: "IN", cultureCode: "en-gb", type: "paid", startDate: "2022-10-21", hub: "Manila", campaignName: "Etho oru campaign Name", finalCampaignName: "ithuthaan kadaisi name"},
];
