import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

// Material UI
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import MenuItem from "@material-ui/core/MenuItem";
import Switch from "@material-ui/core/Switch";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import InputAdornment from "@material-ui/core/InputAdornment";

// Image Select
import { DropzoneArea } from "material-ui-dropzone";

// Form State and Validation
import { useFormik } from "formik";
import * as yup from "yup";

// Date Formatting
import { format } from "date-fns";
import { date_formats } from "utils/constants/global";
import moment from "moment";

import RichTextEditor from "components/Molecules/RichTextEditor/RichTextEditor";

const StyledDiv = styled.div`
  margin-top: 2%;
  display: flex;
`;

const FlexItem = styled.div`
  width: 50%;
  margin-right: 10px;
`;

const HiddenItem = styled.div`
  display: none;
`;

const StyledImage = styled.img`
  color: rgba(0, 0, 0, 0.87);
  width: initial;
  height: 100px;
  opacity: 1;
  z-index: 5;
  max-width: 100%;
  box-shadow: rgb(0 0 0 / 12%) 0 1px 6px, rgb(0 0 0 / 12%) 0 1px 4px;
  box-sizing: border-box;
  transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
  border-radius: 4px;
`;

const validationSchema = yup.object({
  title: yup
    .string("Enter the campaign title")
    .required("Campaign title is required"),
  shortDescription: yup
    .string("Enter the campaign short description")
    .required(
      "Campaign short description is required. This is shown as card preview"
    ),
  // fullDescription: yup
  //   .string("Enter the campaign full description")
  //   .required("Campaign full description is required"),
  newUserCommissionShare: yup.number().required("Enter 0 if not applicable"),
  existingUserCommissionShare: yup
    .number()
    .required("Enter 0 if not applicable"),
  campaignLink: yup
    .string("Enter the campaign link")
    .required("Commision link is required"),
  startDate: yup.date().required("Campaign start date is required"),
  endDate: yup.date().required("Campaign end date is required"),
});

const CampaignForm = (props) => {
  const {
    isMobile,
    onSubmit,
    lobList,
    commissionTypeList,
    countryTypeList,
    submitButtonText,
    onDelete,
  } = props;

  const [fullDescription, setFullDescription] = useState(props.fullDescription);
  const [embed, setEmbed] = useState(props.embed);

  const [uploadedImage, setUploadedImage] = useState();
  const [didEditImage, setDidEditImage] = useState(false);

  const formik = useFormik({
    // mapPropsToValues: (props) => ({
    //   editorState: EditorState.createEmpty(),
    // }),
    initialValues: {
      id: props.id,
      title: props.title,
      shortDescription: props.shortDescription,
      fullDescription: props.fullDescription,
      embed: props.embed,
      imageUrl: props.imageUrl,
      newUserCommissionType: props.newUserCommissionType,
      newUserCommissionShare: props.newUserCommissionShare,
      existingUserCommissionType: props.existingUserCommissionType,
      existingUserCommissionShare: props.existingUserCommissionShare,
      campaignLink: props.campaignLink,
      campaignLob: props.campaignLob,
      isActive: props.isActive,
      publisher: props.publisher,
      publishDate: props.publishDate,
      countryType: props.countryType,

      // date format accepted by the textfield date component
      startDate: moment(props.startDate, "DD/MM/YYYY").format("YYYY-MM-DD"),
      endDate: moment(props.endDate, "DD/MM/YYYY").format("YYYY-MM-DD"),
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      const formResponse = { ...values };
      // values["editorState"] = convertToRaw(editorState.getCurrentContent());

      formResponse["fullDescription"] = fullDescription;
      formResponse["embed"] = embed;
      formResponse["uploadedImage"] =
        uploadedImage && uploadedImage.length >= 1
          ? uploadedImage[0]
          : undefined;

      formResponse["didEditImage"] = didEditImage;

      // format date to one used throughout the app
      formResponse["startDate"] = format(
        new Date(formResponse["startDate"]),
        date_formats.for_display
      );
      formResponse["endDate"] = format(
        new Date(formResponse["endDate"]),
        date_formats.for_display
      );

      onSubmit(formResponse);
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid container spacing={3}>
        <HiddenItem>
          <TextField
            fullWidth
            id="id"
            name="id"
            label="Campaign Id"
            variant="outlined"
            value={formik.values.id}
            onChange={formik.handleChange}
          />
        </HiddenItem>
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            id="title"
            name="title"
            label="Campaign Title"
            variant="outlined"
            value={formik.values.title}
            onChange={formik.handleChange}
            error={formik.touched.title && Boolean(formik.errors.title)}
            helperText={formik.touched.title && formik.errors.title}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            fullWidth
            id="shortDescription"
            name="shortDescription"
            label="Campaign Short Description"
            variant="outlined"
            value={formik.values.shortDescription}
            onChange={formik.handleChange}
            error={
              formik.touched.shortDescription &&
              Boolean(formik.errors.shortDescription)
            }
            helperText={
              formik.errors.shortDescription || formik.touched.shortDescription
            }
          />
        </Grid>
        <Grid item xs={12}>
          <RichTextEditor
            name="embed"
            label="Campaign Details"
            onChange={setEmbed}
            htmlString={embed}
          />
        </Grid>
        <Grid item xs={12}>
          <RichTextEditor
            name="fullDescription"
            label="Campaign Full Rules"
            onChange={setFullDescription}
            htmlString={fullDescription}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            select
            fullWidth
            id="countryType"
            name="countryType"
            label="Target Country"
            variant="outlined"
            value={formik.values.countryType}
            onChange={formik.handleChange}
            helperText="Select the target country for this campaign"
          >
            {countryTypeList.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            select
            fullWidth
            id="newUserCommissionType"
            name="newUserCommissionType"
            label="New User Commission Type"
            variant="outlined"
            value={formik.values.newUserCommissionType}
            onChange={formik.handleChange}
            helperText="Please select the commission type for new user"
          >
            {commissionTypeList.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        </Grid>

        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            id="newUserCommissionShare"
            name="newUserCommissionShare"
            label="New User Commision Share (Only number without symbol)"
            type="number"
            variant="outlined"
            // InputProps={{
            //   endAdornment: <InputAdornment position="end">%</InputAdornment>,
            // }}
            value={formik.values.newUserCommissionShare}
            onChange={formik.handleChange}
            error={
              formik.touched.newUserCommissionShare &&
              Boolean(formik.errors.newUserCommissionShare)
            }
            helperText={
              formik.errors.newUserCommissionShare ||
              "Please select the commission share for new user. Fill 0 if does not apply"
            }
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <TextField
            select
            fullWidth
            id="existingUserCommissionType"
            name="existingUserCommissionType"
            label="Existing User Commission Type"
            variant="outlined"
            value={formik.values.existingUserCommissionType}
            onChange={formik.handleChange}
            helperText="Please select the commission type for existing user."
          >
            {commissionTypeList.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        </Grid>

        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            id="existingUserCommissionShare"
            name="existingUserCommissionShare"
            label="Existing User Commision Share (Only number without symbol)"
            type="number"
            variant="outlined"
            // InputProps={{
            //   endAdornment: <InputAdornment position="end">%</InputAdornment>,
            // }}
            value={formik.values.existingUserCommissionShare}
            onChange={formik.handleChange}
            error={
              formik.touched.existingUserCommissionShare &&
              Boolean(formik.errors.existingUserCommissionShare)
            }
            helperText={
              formik.errors.existingUserCommissionShare ||
              "Please select the commission share for existing user. Fill 0 if does not apply"
            }
          />
        </Grid>

        {/* <Grid item xs={12}>
          <TextField
            fullWidth
            type="url"
            id="imageUrl"
            name="imageUrl"
            label="Campaign Thumbnail Image URL"
            variant="outlined"
            value={formik.values.imageUrl}
            onChange={formik.handleChange}
            error={formik.touched.imageUrl && Boolean(formik.errors.imageUrl)}
            helperText={formik.touched.imageUrl && formik.errors.imageUrl}
          />
        </Grid> */}

        <Grid item xs={12}>
          <TextField
            fullWidth
            type="url"
            id="campaignLink"
            name="campaignLink"
            label="Campaign Link"
            variant="outlined"
            value={formik.values.campaignLink}
            onChange={formik.handleChange}
            error={
              formik.touched.campaignLink && Boolean(formik.errors.campaignLink)
            }
            helperText={
              formik.touched.campaignLink && formik.errors.campaignLink
            }
          />
        </Grid>
        {/* <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            id="publisher"
            name="publisher"
            label="Publisher"
            variant="filled"
            value={formik.values.publisher}
            onChange={formik.handleChange}
            error={formik.touched.publisher && Boolean(formik.errors.publisher)}
            helperText="Author of previous publish. It will be auto updated with new publish"
          />
        </Grid> */}
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            id="startDate"
            label="Campaign Start Date"
            type="date"
            defaultValue={formik.values.startDate}
            onChange={formik.handleChange}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            id="endDate"
            label="Campaign End Date"
            type="date"
            defaultValue={formik.values.endDate}
            onChange={formik.handleChange}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            select
            fullWidth
            id="campaignLob"
            name="campaignLob"
            label="Campaign LOB"
            variant="outlined"
            value={formik.values.campaignLob}
            onChange={formik.handleChange}
            helperText="Select the target LOB for this campaign"
          >
            {lobList.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            id="publishDate"
            name="publishDate"
            label="Last Publish Date"
            variant="filled"
            value={formik.values.publishDate}
            onChange={formik.handleChange}
            error={
              formik.touched.publishDate && Boolean(formik.errors.publishDate)
            }
            helperText="This is the date of previous publish. It will be auto updated with new publish"
            InputProps={{
              readOnly: true,
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography component="span">Campaign Thumbnail</Typography>
          <DropzoneArea
            onChange={(files) => {
              setUploadedImage(files);

              // on change is called on render
              // didEditImage should only be set when user uploads
              if (files.length !== 0) {
                console.log("changed", files.length);
                setDidEditImage(true);
              }
              console.log(files);
            }}
            dropzoneText={"Drag and drop an image here or click"}
            filesLimit={1}
            acceptedFiles={["image/*"]}
            showPreviews={true}
            showPreviewsInDropzone={false}
            maxFileSize={5242880}
          />
          {!didEditImage && formik.values.imageUrl && (
            <>
              <StyledImage src={formik.values.imageUrl} />
            </>
          )}
        </Grid>
        <Grid item xs={12}>
          <Typography component="span">
            <Grid component="label" container alignItems="center" spacing={1}>
              <Grid item>Paused</Grid>
              <Grid item>
                <Switch
                  color="primary"
                  checked={formik.values.isActive}
                  onChange={formik.handleChange}
                  name="isActive"
                  label="Active"
                />
              </Grid>
              <Grid item>Active</Grid>
            </Grid>
          </Typography>
        </Grid>
        {onDelete && (
          <Grid item xs={12} md={2}>
            <Button
              onClick={() => onDelete(props.id)}
              variant="contained"
              color="secondary"
              fullWidth
            >
              Delete Campaign
            </Button>
          </Grid>
        )}

        <Grid item xs={12} md={2}>
          <Button type="submit" variant="contained" color="primary" fullWidth>
            {submitButtonText}
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

CampaignForm.propTypes = {
  id: PropTypes.number,
  title: PropTypes.string,
  shortDescription: PropTypes.string,
  fullDescription: PropTypes.string,
  embed: PropTypes.string,
  imageUrl: PropTypes.string,
  commissionTypeList: PropTypes.arrayOf(
    PropTypes.objectOf({
      value: PropTypes.string,
      label: PropTypes.string,
    })
  ),
  commissionType: PropTypes.string,
  commissionRate: PropTypes.string,
  campaignLink: PropTypes.string,
  publisher: PropTypes.string,
  publishDate: PropTypes.string,
  isActive: PropTypes.bool,
  campaignLob: PropTypes.number,
  lobList: PropTypes.arrayOf(
    PropTypes.objectOf({
      value: PropTypes.number,
      label: PropTypes.string,
    })
  ),
  onSubmit: PropTypes.func,
  submitButtonText: PropTypes.string,
};

CampaignForm.defaultProps = {
  isActive: true,
  submitButtonText: "Submit",
  lobList: [],
  newUserCommissionType: "fixed",
  newUserCommissionShare: 0,
  existingUserCommissionType: "fixed",
  existingUserCommissionShare: 0,
  commissionTypeList: [
    {
      label: "Fixed",
      value: "fixed",
    },
    {
      label: "Revenue Share",
      value: "rev_share",
    },
  ],
  commissionType: "rev_share",
  countryTypeList: [
    {
      label: "MY",
      value: "MY",
    },
    {
      label: "TH",
      value: "TH",
    },
    {
      label: "ID",
      value: "ID",
    },
    {
      label: "SG",
      value: "SG",
    },
  ],
  countryType: "MY",
  startDate: moment().add(1, "days").format("DD/MM/YYYY"),
  endDate: moment().add(7, "days").format("DD/MM/YYYY"),
};

export default CampaignForm;
