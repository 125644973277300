import React, {useContext} from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import _ from "lodash";

import "./GrowthAmbassador.css";
import SSOLogin from "components/SsoLogin";
import { UserContext } from "UserContext";
import { Typography } from "@material-ui/core";
import {Page,Header,HeaderFirstColumn,HeaderSecondColumn,HeaderLogo, Banner, BadgesContainer, BadgeWithTitle} from "./atoms";

const importAll = (require) =>
  require.keys().reduce((acc, next) => {
    acc[next.replace("./", "")] = require(next);
    return acc;
  }, {});
const Images = importAll(
  require.context("assets/img/landing", false, /\.(png|jpe?g|svg)$/)
);

const Nintendo = () => {
  const { user = {} } = useContext(UserContext);

  const [prevScrollPos, setPrevScrollPos] = React.useState(0);
  const [visible, setVisible] = React.useState(true);
  const [activeMenu, setActiveMenu] = React.useState("");
  const handleScroll = () => {
    const currentScrollPos = window.pageYOffset;
    setVisible(
      (prevScrollPos > currentScrollPos &&
        prevScrollPos - currentScrollPos > 70) ||
        currentScrollPos < 150
    );
    setPrevScrollPos(currentScrollPos);
  };

  React.useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [prevScrollPos, visible, handleScroll]);

  const scrollToDiv = (id) => {
    const elem = document.getElementById(id);
    if (elem) {
      elem.scrollIntoView();
      setActiveMenu(id);
    }
  };

  return (
    <Page>
      <Header style={{ boxShadow: visible ? "none" : "rgb(0 0 0 / 16%) 0px 1px 4px" }}>
        <HeaderFirstColumn>
          <HeaderLogo src={Images["aap_logo_white_in_red.svg"]} alt="Logo" />
        </HeaderFirstColumn>
        <HeaderSecondColumn>
          <SSOLogin />
        </HeaderSecondColumn>
      </Header>
      <Banner>
        <Typography variant="h5">Achievements</Typography>
        <div>
          <Typography variant="h4">4/5</Typography>
          <Typography variant="subtitle1">vouchers unlocked</Typography>
        </div>
      </Banner>
      <BadgesContainer>
        <BadgeWithTitle>
          <img src={badgeUrl} />
          <Typography variant="body1">It's a SNAP</Typography>
        </BadgeWithTitle>

        <BadgeWithTitle>
          <img src={badgeUrl} />
          <Typography variant="body1">It's a SNAP</Typography>
        </BadgeWithTitle>

        <BadgeWithTitle>
          <img src={badgeUrl} />
          <Typography variant="body1">It's a SNAP</Typography>
        </BadgeWithTitle>

        <BadgeWithTitle>
          <img src={badgeUrl} />
          <Typography variant="body1">It's a SNAP</Typography>
        </BadgeWithTitle>

        <BadgeWithTitle>
          <img src={badgeUrl} />
          <Typography variant="body1">It's a SNAP</Typography>
        </BadgeWithTitle>
      </BadgesContainer>
    </Page>
  );
};

export default Nintendo;


const badgeUrl = `https://storage.googleapis.com/airasia-gamification-stg.appspot.com/images/flight_badge_unlocked.png?GoogleAccessId=firebase-adminsdk-n9fcr%40airasia-gamification-stg.iam.gserviceaccount.com&Expires=1670509313&Signature=QbrTrbPuS9B0CJ9r9FqNK4mDnZINOiMOyo%2Be%2FisZvYvbHcufrVJ2S7JJqqMK2utS%2B3wtzklHOcYJ9xfPLvyT0GgerZMgVBm0Pdm1z3jEXFRB7dO6O%2Bjv47pbfmFGinCsOKMvq3a7rbsGoH5fm91w0lAE4OGrpQYnzlwdNauYhanQ0zacEomWvdWIXisQBKKR%2BEkk2i9VsVcZmpkpmIWiNz8YH2fthli2HrfEwT2UCnHWeaRkGEz3ZU336LwnaYkJmP%2F6b9ZtcCQnf77bKKM69oCKT1%2Bx3RCNpAnF88dRGoXAj44Mq2sDzvP%2FYVYeIDOoWW8c1ZcTofx7szWsGhxFXA%3D%3D`;