import React from "react";
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Hidden from "@material-ui/core/Hidden";
import Poppers from "@material-ui/core/Popper";
import Divider from "@material-ui/core/Divider";
import { useHistory } from "react-router-dom";
// @material-ui/icons
import Person from "@material-ui/icons/Person";
// core components
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import {auth} from "components/firebase"
// import SSOLogin from "@airasia/phoenix-widgets/widgets/Login";

import styles from "assets/jss/material-dashboard-react/components/headerLinksStyle.js";
import SSOLogin from "components/SsoLogin";

const useStyles = makeStyles(styles);

export default function AdminNavbarLinks() {
  const history = useHistory();
  const classes = useStyles();
  // const [openNotification, setOpenNotification] = React.useState(null);
  // const [openProfile, setOpenProfile] = React.useState(null);

  return (
    <div>
      <div className={classes.manager}>
        <SSOLogin />
      </div>
    </div>
  );
}
