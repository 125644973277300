import axios from "axios";
import { WP_URL, WP_OG_URL } from "../config/wp_api";
import CAMPAIGN from "utils/constants/campaign";
import { userObjKey } from "utils/constants/global";

const formatPayload = (obj, lobList) => {
  const lob = lobList.filter((item) => {
    return item.value === obj.campaignLob;
  });
  console.log("lob", lob);
  return {
    ID: obj.id,
    campaign_name: obj.title,
    campaign_rules: obj.fullDescription,
    short_description: obj.shortDescription,
    full_description: obj.embed,
    link: obj.campaignLink,
    status: obj.isActive
      ? CAMPAIGN.api_status.active
      : CAMPAIGN.api_status.inactive,
    lob: lob[0].label,
    country: obj.countryType,
    start_date: obj.startDate,
    end_date: obj.endDate,
    new_user_commission_share: obj.newUserCommissionShare,
    new_user_commission_type: obj.newUserCommissionType,
    existing_user_commission_share: obj.existingUserCommissionShare,
    existing_user_commission_type: obj.existingUserCommissionType,
    image: obj.imageUrl,
  };
};

const appScriptURL = `${WP_OG_URL}/wp-json/airasia/v1/sync`;

const update = (old, newData, lobList) => {
  const user = JSON.parse(window.localStorage.getItem(userObjKey));
  var oldData = {};

  if (old) {
    oldData = formatPayload(old, lobList);
  }

  return axios.post(appScriptURL, {
    old: oldData,
    new: formatPayload(newData, lobList),
    info: {
      email: user.email,
      user_name: user.displayName,
    },
  });
};

export default { update };
